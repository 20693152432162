import { createSlice } from "@reduxjs/toolkit";



export const userDataSlice = createSlice({
    name:"userDataSlice",
    initialState:{
        userData:null
    },
    reducers:{
        userdata:(state,action)=>{
            console.log(action)
            state.userData = action?.payload
        }
    }
})


export const {userdata} = userDataSlice.actions

export default userDataSlice.reducer