import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { login } from "../Redux/Actions/userAction";
import { userdata } from "../Redux/Reducers/userDataSlice";
import { useDispatch } from "react-redux";
import GoogleLogin from "../Components/Layouts/GoogleLogin";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // ================================================REACT STATES=========================================

  const [userdetails, setUserdetails] = useState({
    email: "",
    password: "",
    // device_token: sessionStorage.getItem("FCM Token"),
  });
  const [show,setshow] = useState(false)

  // ==========================================get data from inputs========================================

  const handlechange = (e) => {
    setUserdetails((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  // ===========================================POST DATA USING REACT QUERY==================================

  const { isPending, mutate } = useMutation({
    mutationFn: login,
    onSuccess: (res) => {
      if (res?.success) {
        toast.success(res?.message);
        console.log(res);
        sessionStorage.setItem("token", res?.data?.token);
        dispatch(userdata(res));
        if (res?.data?.is_profile_created == 0) {
          window.location.href = "/profile-creation";
        } else {
          window.location.href = "/";
        }
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error("Something went wrong");
    },
  });

  // =============================================login=========================================

  const handlelogin = () => {
    let isvalid = true;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!userdetails?.email) {
      toast.error("Please enter email");
      isvalid = false;
    } else if (!emailRegex.test(userdetails?.email)) {
      toast.error("Please Enter a Valid Email");
      isvalid = false;
    } else if (!userdetails?.password) {
      toast.error("Please Enter password");
      isvalid = false;
    }
    if (isvalid) {
      console.log("yiipppeeee", userdetails);

      mutate(userdetails);
    }
  };

  return (
    <>
      <div className="sign-up-flow">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="logo-part">
                <img
                  src={require("../Assets/images/logo-main-sign.svg").default}
                />
                {/* <img
                  src={require("../Assets/Images/cake-r-b.png")}
                  className="cake-r-b"
                /> */}
              </div>
            </Col>
            <Col lg={6}>
              <div className="sign-up-feilds">
                <Form>
                  <h2>Welcome back! Glad to see you, Again!</h2>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      value={userdetails?.email}
                      name="email"
                      onChange={handlechange}
                      placeholder="Enter here..."
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3 position-relative"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      value={userdetails?.password}
                      name="password"
                      onChange={handlechange}
                      type={show ? "text" :"password" }
                      placeholder="Enter here..."
                    />
                    {/* <span className="input-eye-show">
                      <svg
                        width="18"
                        height="14"
                        viewBox="0 0 18 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.126908 6.54176C0.794012 5.40463 1.65272 4.43371 2.64636 3.58536C3.97413 2.45177 5.4719 1.67112 7.17658 1.34325C9.10234 0.972909 10.972 1.1673 12.7756 1.96285C12.9506 2.03998 13.0479 2.01565 13.1777 1.8776C13.7148 1.30638 14.263 0.74622 14.8125 0.187536C15.1389 -0.144312 15.651 -0.0171777 15.7725 0.422631C15.8405 0.668494 15.7576 0.87011 15.5845 1.04739C14.8946 1.75342 14.2085 2.46342 13.5211 3.17195C10.1047 6.69308 6.68906 10.2151 3.26854 13.7321C3.15778 13.846 3.00625 13.947 2.85714 13.9855C2.61503 14.048 2.38379 13.9018 2.2736 13.6772C2.1255 13.3749 2.30394 13.132 2.60515 12.8287C3.45513 11.9734 3.84134 11.5005 3.97084 11.3898C3.89987 11.3385 3.8558 11.3038 3.80915 11.2733C2.30738 10.2916 1.0905 9.01675 0.131916 7.47668C0.130915 7.47521 0.130056 7.47359 0.129054 7.47211C-0.0446623 7.1907 -0.0406556 6.82729 0.126908 6.54176ZM6.22257 9.02826C5.07839 7.25678 5.72818 5.41333 6.60535 4.5138C7.47178 3.62533 9.25774 2.95308 10.9468 4.11411C11.3253 3.72179 11.7004 3.33301 12.0743 2.94541C12.072 2.94409 12.0527 2.92948 12.0312 2.92108C10.1014 2.17125 8.15592 2.12508 6.21098 2.83303C4.11365 3.59642 2.52388 5.03414 1.25706 6.88526C1.19196 6.98039 1.21113 7.04041 1.26694 7.12271C1.87065 8.01546 2.57382 8.81514 3.40061 9.49107C3.84592 9.85508 4.32185 10.1794 4.78791 10.5245C5.27028 10.0251 5.71931 9.56054 6.16791 9.09551C6.18809 9.07472 6.20511 9.04994 6.22257 9.02826ZM10.1177 4.99889C9.29795 4.4259 8.06147 4.61984 7.34643 5.4182C6.6022 6.24929 6.5939 7.45146 7.0684 8.14112C8.08236 7.09631 9.09747 6.05033 10.1177 4.99889Z"
                          fill="#40413A"
                        />
                        <path
                          d="M6.17077 12.3833C6.47199 12.0749 6.75674 11.7765 7.05152 11.4891C7.09072 11.4509 7.18359 11.4477 7.24512 11.4608C9.91468 12.0304 12.2929 11.3597 14.4273 9.66526C15.3113 8.96336 16.0606 8.12593 16.7006 7.18451C16.7011 7.18392 16.7015 7.18333 16.7018 7.18274C16.7758 7.07375 16.7745 6.927 16.7003 6.81815C15.9993 5.79031 15.1619 4.90258 14.1919 4.15614C14.4694 3.87149 14.7395 3.59421 15.0177 3.30882C15.5597 3.72326 16.064 4.20599 16.5334 4.72736C17.029 5.27779 17.4782 5.86538 17.8644 6.50253C18.0506 6.80974 18.0436 7.20324 17.8524 7.50722C16.4278 9.77219 14.5402 11.4636 12.0216 12.3364C10.1342 12.9904 8.22016 13.0034 6.30542 12.4314C6.26707 12.4196 6.22987 12.4044 6.17077 12.3833Z"
                          fill="#40413A"
                        />
                        <path
                          d="M8.1263 10.3663C8.47115 10.013 8.79497 9.67897 9.1228 9.34919C9.15257 9.31925 9.20623 9.30657 9.25058 9.30096C10.3186 9.1638 11.1077 8.35158 11.237 7.25648C11.2425 7.21062 11.2535 7.15472 11.2824 7.12419C11.6026 6.78615 11.9269 6.45238 12.2556 6.11154C12.5579 6.91122 12.4104 8.28595 11.5929 9.2666C10.6566 10.39 9.24014 10.7073 8.1263 10.3663Z"
                          fill="#40413A"
                        />
                      </svg>
                    </span> */}

                    <span
                      className="input-eye-show"
                      style={{ cursor: "pointer" }}
                      onClick={() => setshow(!show)}
                    >
                      {show ? (
                        <svg
                          width="18"
                          height="12"
                          viewBox="0 0 18 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.12249 0C12.8263 0.154398 15.7665 1.80304 17.7323 5.09244C18.089 5.68935 18.0879 6.31372 17.7371 6.91363C16.1908 9.5586 13.9064 11.2207 10.8837 11.8138C6.65697 12.6434 2.37134 10.6459 0.261879 6.92184C-0.0809653 6.31657 -0.0925898 5.69929 0.259013 5.0997C1.82721 2.426 4.14225 0.782249 7.20619 0.182499C7.43486 0.137821 7.66576 0.0985113 7.89761 0.0778303C8.26227 0.045151 8.62837 0.0303112 9.12249 0ZM8.95608 10.877C9.36899 10.8432 9.74607 10.8138 10.123 10.7808C10.2359 10.7709 10.3488 10.7562 10.4601 10.7355C13.1844 10.2295 15.2601 8.80066 16.6877 6.44349C16.8706 6.14133 16.8641 5.87042 16.6867 5.56684C14.8045 2.34612 11.1281 0.633693 7.42642 1.30954C4.71997 1.80367 2.68106 3.27219 1.28261 5.61941C1.12576 5.88258 1.12608 6.1347 1.28436 6.39676C3.0282 9.28454 5.62509 10.7218 8.95608 10.877Z"
                            fill="#40413A"
                          />
                          <path
                            d="M5.50105 6.00556C5.50216 4.08348 7.07418 2.53051 9.00879 2.54014C10.9445 2.54993 12.496 4.0928 12.4954 6.00745C12.4949 7.92953 10.9249 9.48014 8.98777 9.47193C7.05284 9.46372 5.49993 7.9199 5.50105 6.00556ZM11.3493 6.00493C11.3484 4.73075 10.2819 3.67507 8.99685 3.67634C7.71098 3.6776 6.64662 4.73375 6.64726 6.00761C6.64789 7.28052 7.7156 8.33763 8.99971 8.33715C10.2841 8.33668 11.3503 7.27847 11.3493 6.00493Z"
                            fill="#40413A"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="18"
                          height="14"
                          viewBox="0 0 18 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.126908 6.54176C0.794012 5.40463 1.65272 4.43371 2.64636 3.58536C3.97413 2.45177 5.4719 1.67112 7.17658 1.34325C9.10234 0.972909 10.972 1.1673 12.7756 1.96285C12.9506 2.03998 13.0479 2.01565 13.1777 1.8776C13.7148 1.30638 14.263 0.74622 14.8125 0.187536C15.1389 -0.144312 15.651 -0.0171777 15.7725 0.422631C15.8405 0.668494 15.7576 0.87011 15.5845 1.04739C14.8946 1.75342 14.2085 2.46342 13.5211 3.17195C10.1047 6.69308 6.68906 10.2151 3.26854 13.7321C3.15778 13.846 3.00625 13.947 2.85714 13.9855C2.61503 14.048 2.38379 13.9018 2.2736 13.6772C2.1255 13.3749 2.30394 13.132 2.60515 12.8287C3.45513 11.9734 3.84134 11.5005 3.97084 11.3898C3.89987 11.3385 3.8558 11.3038 3.80915 11.2733C2.30738 10.2916 1.0905 9.01675 0.131916 7.47668C0.130915 7.47521 0.130056 7.47359 0.129054 7.47211C-0.0446623 7.1907 -0.0406556 6.82729 0.126908 6.54176ZM6.22257 9.02826C5.07839 7.25678 5.72818 5.41333 6.60535 4.5138C7.47178 3.62533 9.25774 2.95308 10.9468 4.11411C11.3253 3.72179 11.7004 3.33301 12.0743 2.94541C12.072 2.94409 12.0527 2.92948 12.0312 2.92108C10.1014 2.17125 8.15592 2.12508 6.21098 2.83303C4.11365 3.59642 2.52388 5.03414 1.25706 6.88526C1.19196 6.98039 1.21113 7.04041 1.26694 7.12271C1.87065 8.01546 2.57382 8.81514 3.40061 9.49107C3.84592 9.85508 4.32185 10.1794 4.78791 10.5245C5.27028 10.0251 5.71931 9.56054 6.16791 9.09551C6.18809 9.07472 6.20511 9.04994 6.22257 9.02826ZM10.1177 4.99889C9.29795 4.4259 8.06147 4.61984 7.34643 5.4182C6.6022 6.24929 6.5939 7.45146 7.0684 8.14112C8.08236 7.09631 9.09747 6.05033 10.1177 4.99889Z"
                            fill="#40413A"
                          />
                          <path
                            d="M6.17077 12.3833C6.47199 12.0749 6.75674 11.7765 7.05152 11.4891C7.09072 11.4509 7.18359 11.4477 7.24512 11.4608C9.91468 12.0304 12.2929 11.3597 14.4273 9.66526C15.3113 8.96336 16.0606 8.12593 16.7006 7.18451C16.7011 7.18392 16.7015 7.18333 16.7018 7.18274C16.7758 7.07375 16.7745 6.927 16.7003 6.81815C15.9993 5.79031 15.1619 4.90258 14.1919 4.15614C14.4694 3.87149 14.7395 3.59421 15.0177 3.30882C15.5597 3.72326 16.064 4.20599 16.5334 4.72736C17.029 5.27779 17.4782 5.86538 17.8644 6.50253C18.0506 6.80974 18.0436 7.20324 17.8524 7.50722C16.4278 9.77219 14.5402 11.4636 12.0216 12.3364C10.1342 12.9904 8.22016 13.0034 6.30542 12.4314C6.26707 12.4196 6.22987 12.4044 6.17077 12.3833Z"
                            fill="#40413A"
                          />
                          <path
                            d="M8.1263 10.3663C8.47115 10.013 8.79497 9.67897 9.1228 9.34919C9.15257 9.31925 9.20623 9.30657 9.25058 9.30096C10.3186 9.1638 11.1077 8.35158 11.237 7.25648C11.2425 7.21062 11.2535 7.15472 11.2824 7.12419C11.6026 6.78615 11.9269 6.45238 12.2556 6.11154C12.5579 6.91122 12.4104 8.28595 11.5929 9.2666C10.6566 10.39 9.24014 10.7073 8.1263 10.3663Z"
                            fill="#40413A"
                          />
                        </svg>
                      )}
                    </span>
                    <div className="forgot-page-link">
                      <Link to="/forgot-password">Forgot Password?</Link>
                    </div>
                  </Form.Group>
                  {/* <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Example textarea</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group> */}
                  <Button type="button" className="mt-2" onClick={handlelogin}>
                    Login
                  </Button>

                  <div className="sign-line-part">
                    <hr />
                    <span>Or</span>
                  </div>
                  {/* <div className="connect-google">
                    <Link to="">
                      <img src={require("../Assets/images/google-icon.png")} />
                      Continue with Google
                    </Link>
                  </div> */}
                  <GoogleLogin/>

                  <div className="connect-sign-page">
                    <p>
                      Don’t have Account? <Link to="/sign-up">Sign Up</Link>
                    </p>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
