import { Api } from "../../services/userapi"


// =============================================registration=====================================

export const userRegister=async(details)=>{
    const {data} = await Api.post("/user/register",details)
    return data

}
// =============================================verifyOTP=====================================

export const verifyOTP=async(details)=>{
    const {data} = await Api.post("/user/verifyOtp",details)
    return data

}
// =============================================verifyOTP=====================================

export const resendOTP=async(details)=>{
    const {data} = await Api.post("/user/resendOtp",details)
    return data

}

// =========================================allow location====================================

export const allowLocation=async(details)=>{
    const {data} = await Api.put("/user/allowLocation",details)
    return data

}
// =========================================allow location====================================

export const login=async(details)=>{
    const {data} = await Api.post("/user/login",details)
    return data

}
// =========================================create profile====================================

export const createProfile=async(details)=>{
    const {data} = await Api.put("/user/createProfile",details)
    return data

}
// =========================================forgot password====================================

export const forgetPassword=async(details)=>{
    const {data} = await Api.post("/user/forgotPassword",details)
    return data

}
// =========================================reset password====================================

export const resetPassword=async(details)=>{
    const {data} = await Api.post("/user/resetPassword",details)
    return data

}
// =========================================logout====================================

export const logout=async(details)=>{
    const {data} = await Api.patch("/user/logout",details)
    return data

}
// =========================================delete account====================================

export const deleteAccount=async(details)=>{
    const {data} = await Api.patch("/user/deleteAccount",details)
    return data

}
// =========================================delete account====================================

export const changePassword=async(details)=>{
    const {data} = await Api.patch("/user/changePassword",details)
    return data

}
// =========================================get addresses====================================

export const getAddresses=async(details)=>{
    const {data} = await Api.get("/user/getAddresses",details)
    return data

}
// =========================================add addresses====================================

export const addAddresses=async(details)=>{
    const {data} = await Api.post("/user/addAddress",details)
    return data

}
// =========================================delete addresses====================================

export const deleteAddress=async(details)=>{
    const {data} = await Api.delete(`/user/deleteAddress?addressId=${details}`,)
    return data

}
// =========================================edit addresses====================================

export const editAddresses=async(details)=>{
    const {data} = await Api.put("/user/editAddress",details)
    return data

}

// =========================================delete account====================================

export const changeNotificationStatus=async(details)=>{
    const {data} = await Api.patch("/user/changeNotificationStatus",details)
    return data

}

// // =========================================get products====================================

export const getProducts=async(details)=>{
    const {data} = await Api.get("/user/getProducts",details)
    return data

}

// // =========================================get products====================================

export const getProductbyId=async(details)=>{
    console.log(details,"details")
    const {data} = await Api.get(`/user/getProductById?productId=${details}`)
    return data

}

// ========================================addRemoveQuantity in cart===========================


export const addRemoveQuantity=async(details)=>{
    const {data} = await Api.put("/user/addRemoveQuantity",details)
    return data

}

// =========================================edit profile====================================

export const editProfile =async(details)=>{
    const {data} = await Api.put("/user/editProfile ",details)
    return data

}


// =====================================socila login===========================================

export const socialLogin =async(details)=>{
    const {data} = await Api.post("/user/socialLogin ",details)
    return data

}
// =====================================add to cart ===========================================

export const addTocart =async(details)=>{
    const {data} = await Api.post("/user/addToCart ",details)
    return data

}
// =====================================add to cart ===========================================

export const getCart  =async(details)=>{
    const {data} = await Api.get("/user/getCart")
    return data

}
// =====================================delete from cart ===========================================

export const removeFromCart   =async(details)=>{
    const {data} = await Api.delete(`/user/removeFromCart?cartId=${details}`)
    return data

}

// ====================================place order ===========================================

export const placeOrder =async(details)=>{
    const {data} = await Api.post("/user/placeOrder ",details)
    return data

}
// ====================================createPaymentIntent ===========================================

export const createPaymentIntent =async(details)=>{
    const {data} = await Api.post("/user/createPaymentIntent ",details)
    return data

}

// payment confirmed

export const paymentDoneforAppointment = async (details) => {
    const { data } = await Api.post(
      `user/paymentDoneforAppointment`,
      details
    );
  
    return data;
  };

//   ======================================get support chat=================================

export const getSupportChat  =async(details)=>{
    const {data} = await Api.get("/user/getSupport")
    return data

}
//   ======================================get order=================================

export const getMyOrders  =async(details)=>{
    console.log(details,"===============================================")

    let url = "/user/getMyOrders"

    if(details){
        url+=`?filter=${details}`
    }
    const {data} = await Api.get(url)
    return data

}
//   ======================================get order by id=================================

export const getOrderProductById  =async(details)=>{
    const {data} = await Api.get(`/user/getOrderProductById?orderId=${details} `)
    return data

}
//   ======================================getTransactions=================================

export const getTransactions  =async(details)=>{
    const {data} = await Api.get(`/user/getTransactions`)
    return data

}

// ========================================create support====================================

export const createSupport = async (details) => {
    const { data } = await Api.post(
      `user/createSupport`,
      details
    );
  
    return data;
  };
