import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Col, Container, Dropdown, ProgressBar, Row } from "react-bootstrap";
import Googlemaps from "../Components/Layouts/GoogleMap";
// import ProgressBar from "@ramonak/react-progress-bar";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../Components/Layouts/Layout";
import { getMyOrders } from "../Redux/Actions/userAction";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import moment from "moment";

export default function Orders() {
  const navigate = useNavigate();
  const [type, setType] = useState(4);
  const [filter, setFilter] = useState(null);
  // =========================================api for get address==============================

  const { isPending, data, isError, isSuccess, refetch } = useQuery({
    queryKey: ["getMyOrders", filter],
    queryFn: () => getMyOrders(filter),
  });

  // {
  //   isSuccess && dispatch(address(addressData));
  // }

  {
    isError && toast.error("Something went wrong");
  }

  console.log(data, "data");

  return (
    <Layout>
      <section className="our-products-sec">
        <Container>
          <Row className="cart-header">
            <h2>Orders</h2>
            <Tabs>
              <div className="order-tab">
                <TabList className="tabs-switch">
                  <Tab>
                    <span
                      onClick={() => {
                        refetch();
                      }}
                    >
                      {" "}
                      In Progress
                    </span>
                  </Tab>
                  <Tab>
                    <span
                      onClick={() => {
                        refetch();
                      }}
                    >
                      {" "}
                      Completed
                    </span>
                  </Tab>
                </TabList>
                <div className="filter-dropdowns">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="add-theme-color"
                    >
                      <img
                        src={require("../Assets/images/filters.svg").default}
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className="filters">
                        {[
                          { label: "Few Days Back", value: 0 },
                          { label: "One Week ago", value: 1 },
                          { label: "One Month ago", value: 2 },
                          { label: "Three Months ago", value: 3 },
                          { label: "Six Months ago", value: 4 },
                        ].map((item) => (
                          <div className="form-check" key={item.value}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="filterRadio"
                              id={`filterRadio${item.value}`}
                              checked={filter === item.value}
                              onChange={() => {
                                setFilter(item.value);
                                refetch();
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`filterRadio${item.value}`}
                            >
                              {item.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <TabPanel>
                <div className="cart-card">
                  <Row>
                    <Col lg={12}>
                      <div className="our-cart-card">
                        <Row>
                          {data?.data?.inProgressOrders?.length > 0 &&
                            data?.data?.inProgressOrders?.map((product) => {
                              return (
                                <Col lg={4} className="mb-4">
                                  <div className="orders-card">
                                    <div className="completed-order-top orderscard-top">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="86"
                                        height="80"
                                        viewBox="0 0 86 80"
                                        fill="none"
                                      >
                                        <rect
                                          width="86"
                                          height="80"
                                          rx="8"
                                          fill="#209920"
                                        />
                                        <path
                                          d="M32.1449 34.9043C33.9408 35.5713 35.6675 36.2121 37.3991 36.8553C37.3991 43.2645 37.3991 49.6545 37.3991 56.1144C36.6926 55.8516 36.0151 55.5988 35.336 55.3475C30.4673 53.541 25.5977 51.7353 20.729 49.928C20.1078 49.6975 20.0008 49.5438 20.0008 48.8891C20 42.888 20 36.8868 20 30.8856C20 30.745 20 30.6052 20 30.3962C20.8449 30.7089 21.6269 30.9986 22.409 31.2883C23.5065 31.6947 24.6016 32.1081 25.7031 32.5039C25.9534 32.5938 26.0467 32.7175 26.0443 32.978C26.0306 34.3657 26.0371 35.7542 26.0387 37.1427C26.0395 37.6683 26.0934 37.7067 26.6083 37.5576C27.2585 37.3693 27.915 37.1988 28.5531 36.9805C28.8548 36.8768 29.0552 36.9352 29.2805 37.1242C29.9966 37.7251 30.7216 38.3175 31.4546 38.8992C31.6058 39.0191 31.807 39.0821 31.9856 39.1712C32.0387 38.9738 32.1345 38.7771 32.1369 38.5788C32.1522 37.3801 32.1449 36.1806 32.1449 34.9043Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M46.7689 53.1898C44.1539 54.1619 41.5461 55.1308 38.8933 56.1167C38.8836 55.9369 38.8707 55.8001 38.8707 55.6641C38.8699 49.5354 38.8723 43.4066 38.8627 37.2787C38.8619 36.979 38.9432 36.8307 39.2513 36.717C44.7976 34.6677 50.3382 32.6053 55.8796 30.5468C55.989 30.506 56.1001 30.4722 56.2513 30.4215C56.2513 33.6027 56.2513 36.7431 56.2513 39.9258C52.7416 40.0464 49.8803 41.4004 47.8825 44.1842C45.8894 46.9605 45.6279 49.9879 46.7689 53.1898Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M56.257 41.3258C61.0814 41.3212 65.0176 45.0787 64.9999 49.6714C64.9822 54.2556 61.0605 57.9969 56.2698 58C51.463 58.0031 47.5083 54.221 47.5276 49.6406C47.5461 45.0441 51.4453 41.3304 56.257 41.3258ZM54.4031 50.8017C53.7996 50.0801 53.2428 49.4155 52.6877 48.7493C52.3731 48.3712 51.9732 48.1622 51.463 48.246C50.4693 48.4097 50.0751 49.4247 50.7107 50.2108C51.5057 51.1951 52.3256 52.1602 53.1366 53.1322C53.8568 53.9959 54.564 54.0343 55.3831 53.2529C57.3005 51.4233 59.2163 49.593 61.1313 47.7619C61.2488 47.6497 61.3687 47.5368 61.4669 47.4092C61.9472 46.7807 61.6785 45.8909 60.9237 45.6035C60.3388 45.3806 59.8665 45.5743 59.4489 45.9754C58.4021 46.9797 57.3512 47.9794 56.302 48.9821C55.6833 49.5753 55.0653 50.1677 54.4031 50.8017Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M44.1748 25.1626C42.6259 25.7366 41.1937 26.266 39.7614 26.7985C35.6724 28.3192 31.5833 29.8383 27.4974 31.3659C27.1724 31.4873 26.8924 31.5011 26.5552 31.372C24.7529 30.6797 22.9385 30.015 21.0613 29.3158C21.219 29.2382 21.3139 29.1798 21.4177 29.1414C26.8586 27.119 32.2994 25.0973 37.7443 23.0841C37.9285 23.0157 38.1772 22.9665 38.3493 23.028C40.2539 23.7096 42.1488 24.4157 44.1748 25.1626Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M33.129 33.7994C34.8396 33.1639 36.4255 32.5738 38.0114 31.9844C41.9379 30.526 45.8661 29.0714 49.7886 27.6046C50.1193 27.4809 50.4001 27.4663 50.7365 27.5961C52.2041 28.1609 53.6822 28.6995 55.2399 29.2781C55.0581 29.3619 54.9494 29.4203 54.8344 29.4633C49.4338 31.4719 44.0316 33.4767 38.6334 35.4914C38.3035 35.6143 38.0235 35.6305 37.6839 35.4998C36.2163 34.9335 34.7366 34.3964 33.129 33.7994Z"
                                          fill="white"
                                        />
                                      </svg>
                                      <div className="order-data-card">
                                        <div className="order-data-card-item">
                                          <div className="price-label">
                                            <b>Order Id {product?.orderId}</b>
                                          </div>
                                          <p>
                                            Price: $
                                            {product?.productId?.price *
                                              product?.order_quantity +
                                              product?.productId?.price *
                                                product?.order_quantity *
                                                0.03}
                                          </p>
                                        </div>
                                        <div className="order-data-card-item">
                                          <div className="price-label">
                                            Placed on{" "}
                                            {moment(product?.createdAt).format(
                                              "DD MMM YYYY"
                                            )}
                                          </div>
                                          <p>
                                            Item: {product?.order_quantity}{" "}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="progress-bottom">
                                      <ul>
                                        <li>
                                          <h3>
                                            {" "}
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="10"
                                              height="9"
                                              viewBox="0 0 10 9"
                                              fill="none"
                                            >
                                              <ellipse
                                                cx="4.84211"
                                                cy="4.37122"
                                                rx="4.84211"
                                                ry="4.20619"
                                                fill="#5F6368"
                                              />
                                            </svg>
                                            Order Placed
                                          </h3>
                                          <p>
                                            {moment(product?.createdAt).format(
                                              "DD MMM YYYY"
                                            )}
                                          </p>
                                        </li>
                                        {product?.order_statuses?.length > 0 &&
                                          product?.order_statuses?.map(
                                            (status) => {
                                              return (
                                                <li>
                                                  <h3>
                                                    {" "}
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="10"
                                                      height="9"
                                                      viewBox="0 0 10 9"
                                                      fill="none"
                                                    >
                                                      <ellipse
                                                        cx="4.84211"
                                                        cy="4.37122"
                                                        rx="4.84211"
                                                        ry="4.20619"
                                                        fill="#5F6368"
                                                      />
                                                    </svg>
                                                    {status?.status == 0
                                                      ? "Order Placed"
                                                      : status?.status == 1
                                                      ? "Order Confirmed"
                                                      : status?.status == 2
                                                      ? "Order Shipped"
                                                      : status?.status == 3
                                                      ? "Out for Delivery"
                                                      : status?.status == 4
                                                      ? "Order Delivered"
                                                      : "Order Cancelled"}
                                                  </h3>
                                                  <p>{moment(status?.updatedAt).format("DD MMM YYYY")}</p>
                                                </li>
                                              );
                                            }
                                          )}

                                        {/* <li>
                                          <h3>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="10"
                                              height="9"
                                              viewBox="0 0 10 9"
                                              fill="none"
                                            >
                                              <ellipse
                                                cx="4.84211"
                                                cy="4.37122"
                                                rx="4.84211"
                                                ry="4.20619"
                                                fill="#5F6368"
                                              />
                                            </svg>
                                            Order Confirmed
                                          </h3>
                                          <p>Jun 19 2024</p>
                                        </li>
                                        <li>
                                          <h3>
                                            <svg
                                              width="8"
                                              height="8"
                                              viewBox="0 0 8 8"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <circle
                                                cx="4"
                                                cy="4"
                                                r="4"
                                                fill="black"
                                              />
                                            </svg>
                                            Out for Delivery
                                          </h3>
                                          <p>Pending</p>
                                        </li>
                                        <li>
                                          <h3>
                                            <svg
                                              width="8"
                                              height="8"
                                              viewBox="0 0 8 8"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <circle
                                                cx="4"
                                                cy="4"
                                                r="4"
                                                fill="black"
                                              />
                                            </svg>
                                            Order Delivered
                                          </h3>
                                          <p>Pending</p>
                                        </li> */}
                                      </ul>

                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          navigate(
                                            `/orders-details/${product?._id}`
                                          );
                                        }}
                                      >
                                        Details{" "}
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="5"
                                          height="9"
                                          viewBox="0 0 5 9"
                                          fill="none"
                                        >
                                          <path
                                            d="M0.658441 9C0.409055 8.91085 0.258018 8.69541 0.0964439 8.49853C-0.0352743 8.33881 -0.0229806 8.07879 0.103469 7.91349C0.133325 7.87449 0.166694 7.8392 0.200062 7.80391C1.21517 6.72854 2.23203 5.65503 3.24714 4.58152C3.26997 4.55737 3.29983 4.54066 3.33144 4.51651C3.2928 4.47379 3.27173 4.44965 3.2489 4.4255C2.22501 3.34085 1.20112 2.25991 0.177231 1.17525C-0.00541814 0.983952 -0.0598616 0.744362 0.075369 0.551205C0.200062 0.372905 0.358124 0.213178 0.524967 0.0775965C0.681273 -0.048699 0.893778 -0.0115533 1.04833 0.122171C1.07116 0.142601 1.09399 0.164889 1.11506 0.187176C2.35146 1.49471 3.58961 2.80409 4.826 4.11162C5.01041 4.30849 5.0508 4.53694 4.93489 4.74681C4.90679 4.79882 4.8664 4.84525 4.826 4.88983C3.58961 6.19921 2.35322 7.5086 1.11331 8.81241C1.03779 8.89228 0.932415 8.93871 0.841091 9C0.779622 9 0.718154 9 0.658441 9Z"
                                            fill="#8391A1"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              );
                            })}

                          {data?.data?.inProgressOrders?.length == 0 && (
                            <div style={{ textAlign: "center" }}>
                              <h4>No order found</h4>
                            </div>
                          )}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="cart-card">
                  <Row>
                    <Col lg={12}>
                      <div className="our-cart-card">
                        <Row>
                          {data?.data?.completedOrders?.length > 0 &&
                            data?.data?.completedOrders?.map((order) => {
                              return (
                                <Col lg={4}>
                                  <div className="completed-order-card ">
                                    <div className="completed-order-top">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="86"
                                        height="80"
                                        viewBox="0 0 86 80"
                                        fill="none"
                                      >
                                        <rect
                                          width="86"
                                          height="80"
                                          rx="8"
                                          fill="#209920"
                                        />
                                        <path
                                          d="M32.1449 34.9043C33.9408 35.5713 35.6675 36.2121 37.3991 36.8553C37.3991 43.2645 37.3991 49.6545 37.3991 56.1144C36.6926 55.8516 36.0151 55.5988 35.336 55.3475C30.4673 53.541 25.5977 51.7353 20.729 49.928C20.1078 49.6975 20.0008 49.5438 20.0008 48.8891C20 42.888 20 36.8868 20 30.8856C20 30.745 20 30.6052 20 30.3962C20.8449 30.7089 21.6269 30.9986 22.409 31.2883C23.5065 31.6947 24.6016 32.1081 25.7031 32.5039C25.9534 32.5938 26.0467 32.7175 26.0443 32.978C26.0306 34.3657 26.0371 35.7542 26.0387 37.1427C26.0395 37.6683 26.0934 37.7067 26.6083 37.5576C27.2585 37.3693 27.915 37.1988 28.5531 36.9805C28.8548 36.8768 29.0552 36.9352 29.2805 37.1242C29.9966 37.7251 30.7216 38.3175 31.4546 38.8992C31.6058 39.0191 31.807 39.0821 31.9856 39.1712C32.0387 38.9738 32.1345 38.7771 32.1369 38.5788C32.1522 37.3801 32.1449 36.1806 32.1449 34.9043Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M46.7689 53.1898C44.1539 54.1619 41.5461 55.1308 38.8933 56.1167C38.8836 55.9369 38.8707 55.8001 38.8707 55.6641C38.8699 49.5354 38.8723 43.4066 38.8627 37.2787C38.8619 36.979 38.9432 36.8307 39.2513 36.717C44.7976 34.6677 50.3382 32.6053 55.8796 30.5468C55.989 30.506 56.1001 30.4722 56.2513 30.4215C56.2513 33.6027 56.2513 36.7431 56.2513 39.9258C52.7416 40.0464 49.8803 41.4004 47.8825 44.1842C45.8894 46.9605 45.6279 49.9879 46.7689 53.1898Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M56.257 41.3258C61.0814 41.3212 65.0176 45.0787 64.9999 49.6714C64.9822 54.2556 61.0605 57.9969 56.2698 58C51.463 58.0031 47.5083 54.221 47.5276 49.6406C47.5461 45.0441 51.4453 41.3304 56.257 41.3258ZM54.4031 50.8017C53.7996 50.0801 53.2428 49.4155 52.6877 48.7493C52.3731 48.3712 51.9732 48.1622 51.463 48.246C50.4693 48.4097 50.0751 49.4247 50.7107 50.2108C51.5057 51.1951 52.3256 52.1602 53.1366 53.1322C53.8568 53.9959 54.564 54.0343 55.3831 53.2529C57.3005 51.4233 59.2163 49.593 61.1313 47.7619C61.2488 47.6497 61.3687 47.5368 61.4669 47.4092C61.9472 46.7807 61.6785 45.8909 60.9237 45.6035C60.3388 45.3806 59.8665 45.5743 59.4489 45.9754C58.4021 46.9797 57.3512 47.9794 56.302 48.9821C55.6833 49.5753 55.0653 50.1677 54.4031 50.8017Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M44.1748 25.1626C42.6259 25.7366 41.1937 26.266 39.7614 26.7985C35.6724 28.3192 31.5833 29.8383 27.4974 31.3659C27.1724 31.4873 26.8924 31.5011 26.5552 31.372C24.7529 30.6797 22.9385 30.015 21.0613 29.3158C21.219 29.2382 21.3139 29.1798 21.4177 29.1414C26.8586 27.119 32.2994 25.0973 37.7443 23.0841C37.9285 23.0157 38.1772 22.9665 38.3493 23.028C40.2539 23.7096 42.1488 24.4157 44.1748 25.1626Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M33.129 33.7994C34.8396 33.1639 36.4255 32.5738 38.0114 31.9844C41.9379 30.526 45.8661 29.0714 49.7886 27.6046C50.1193 27.4809 50.4001 27.4663 50.7365 27.5961C52.2041 28.1609 53.6822 28.6995 55.2399 29.2781C55.0581 29.3619 54.9494 29.4203 54.8344 29.4633C49.4338 31.4719 44.0316 33.4767 38.6334 35.4914C38.3035 35.6143 38.0235 35.6305 37.6839 35.4998C36.2163 34.9335 34.7366 34.3964 33.129 33.7994Z"
                                          fill="white"
                                        />
                                      </svg>
                                      <div className="order-data-card">
                                        <div className="order-data-card-item">
                                          <div className="price-label">
                                            <b>Order Id {order?.orderId}</b>
                                          </div>
                                          <p>
                                            Price: $
                                            {order?.productId?.price *
                                              order?.order_quantity *
                                              0.03}
                                          </p>
                                        </div>
                                        <div className="order-data-card-item">
                                          <div className="price-label">
                                            Placed on{" "}
                                            {moment(order?.createdAt).format(
                                              "DD MMM YYYY"
                                            )}
                                          </div>
                                          <p>Item: {order?.order_quantity} </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="completed-order-bottom">
                                      <div className="order-track-date">
                                        <h4>
                                          Delivered on{" "}
                                          {moment(order?.date).format(
                                            "DD MMM YYYY"
                                          )}
                                        </h4>
                                        <p
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            navigate(
                                              `/orders-details/${order?._id}`
                                            );
                                          }}
                                        >
                                          Details
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="5"
                                            height="9"
                                            viewBox="0 0 5 9"
                                            fill="none"
                                          >
                                            <path
                                              d="M0.658441 9C0.409055 8.91085 0.258018 8.69541 0.0964439 8.49853C-0.0352743 8.33881 -0.0229806 8.07879 0.103469 7.91349C0.133325 7.87449 0.166694 7.8392 0.200062 7.80391C1.21517 6.72854 2.23203 5.65503 3.24714 4.58152C3.26997 4.55737 3.29983 4.54066 3.33144 4.51651C3.2928 4.47379 3.27173 4.44965 3.2489 4.4255C2.22501 3.34085 1.20112 2.25991 0.177231 1.17525C-0.00541814 0.983952 -0.0598616 0.744362 0.075369 0.551205C0.200062 0.372905 0.358124 0.213178 0.524967 0.0775965C0.681273 -0.048699 0.893778 -0.0115533 1.04833 0.122171C1.07116 0.142601 1.09399 0.164889 1.11506 0.187176C2.35146 1.49471 3.58961 2.80409 4.826 4.11162C5.01041 4.30849 5.0508 4.53694 4.93489 4.74681C4.90679 4.79882 4.8664 4.84525 4.826 4.88983C3.58961 6.19921 2.35322 7.5086 1.11331 8.81241C1.03779 8.89228 0.932415 8.93871 0.841091 9C0.779622 9 0.718154 9 0.658441 9Z"
                                              fill="#8391A1"
                                            />
                                          </svg>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              );
                            })}

                          {data?.data?.completedOrders?.length == 0 && (
                            <div style={{ textAlign: "center" }}>
                              <h4>No order found</h4>
                            </div>
                          )}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </TabPanel>
            </Tabs>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}
