import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { resendOTP, verifyOTP } from "../Redux/Actions/userAction";
import { useMutation } from "@tanstack/react-query";
import { userdata } from "../Redux/Reducers/userDataSlice";

export default function OTPVerification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // =========================================registerationData from redux===================================

  const registartionData = useSelector(
    (state) => state.registration.registrationData
  );
  console.log(registartionData, "registration");

  // ==================================================react satate=============================================

  const [otp, setOtp] = useState("");
  const [purpose, setPurpose] = useState("");

  
  useEffect(() => {
    if (location?.state) {
      setPurpose(location?.state?.purpose);
    }
  }, [location]);

  // =============================================verifyOTP API==========================================

  const { isPending, isError, isSuccess, mutate } = useMutation({
    mutationFn: verifyOTP,
    onSuccess: (res) => {
      console.log(res, "res");
      if (res?.success) {
        dispatch(userdata(res));
        purpose == "forgetPass"
          ? toast.success("OTP verified successfully")
          : toast.success(res?.message);

       purpose == "forgetPass"
          ?window.location.href = "/reset-password"
          :window.location.href = "/allow-location" 

        sessionStorage?.setItem("token", res?.data?.token);
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  // ============================================submission=====================================================

  const verifyOtp = () => {
    let isvalid = true;

    if (otp.length != 4) {
      toast.error("Please enter OTP");
      isvalid = false;
    }
    if (isvalid) {
      mutate({
        userId: registartionData?._id,
        otp: otp,
      });
    }
  };

  const { isPending: resendLoading, mutate: resendMutate } = useMutation({
    mutationFn: resendOTP,
    onSuccess: (res) => {
      console.log(res, "res");
      if (res?.success) {
        toast.success(res?.message);
        // swal({
        //   title: "OTP",
        //   text: `${res?.data?.otp_email}`,
        //   icon: "success",
        //   // dangerMode: true,
        // });

        // navigate("/account-created-sign");
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  //  ==============================================sendOTPAGAIN==========================================

  const sendOTPagain = () => {
    setOtp("");
    resendMutate({ userId: registartionData?._id });
  };

  return (
    <>
      <div className="sign-up-flow respo-height-100vh">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="logo-part">
                <img
                  src={require("../Assets/images/logo-main-sign.svg").default}
                />
                {/* <img
                  src={require("../Assets/Images/cake-r-b.png")}
                  className="cake-r-b"
                /> */}
              </div>
            </Col>
            <Col lg={6}>
              <div className="sign-up-feilds">
                <Form>
                  <h2>OTP Verification</h2>
                  <p>
                    Enter the verification code we just sent on your email
                    address.
                  </p>
                  <div className="form-set height-none justify-content-add login-box-inner-wrap">
                    <Form.Label>Code</Form.Label>
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      separator={<span style={{ width: "8px" }}></span>}
                      renderInput={(props) => <input {...props} />}
                      Style={{
                        border: "1px solid transparent",
                        borderRadius: "8px",
                        width: "100px",
                        height: "54px",
                        fontSize: "12px",
                        color: "#000",
                        fontWeight: "400",
                        caretColor: "blue",
                        // display: "flex",
                        borderBlock: "1px solid var(--Border, #E8ECF4)",
                        justifyContent: "space-between",
                      }}
                    />
                  </div>
                  {/* <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Example textarea</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group> */}
                  <Button onClick={verifyOtp} type="button" className="mt-2">
                    {isPending ? "Verifying..." : "Verify"}
                  </Button>
                  <div className="connect-sign-page">
                    <p>
                      Didn’t get OTP?{" "}
                      <Link onClick={sendOTPagain} to="">
                        Resend OTP
                      </Link>
                    </p>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
