import React, { useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import { Col, Container, Row, Button, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Layout from "../Components/Layouts/Layout";
import { io } from "socket.io-client";
import { createSupport, getSupportChat } from "../Redux/Actions/userAction";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import moment from "moment/moment";

export default function Support() {
  // ===========================================userData from redux=======================================

  const userData = useSelector((state) => state?.userData?.userData);

  console.log(userData, "userfromredux");

  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  const [createsupportdata, setcreatesupportdata] = useState(null);
  const chatContainerRef = useRef(null);

  // const newcconnection = io("https://just-in-api.bosselt.com/");
  const socket = io("https://just-in-api.bosselt.com");

  const [message, setMessage] = useState(null);

  // ============================================create support==================================================

  useEffect(() => {
    const createsupport = async () => {
      let data = await createSupport();
      if (data?.success) {
        setcreatesupportdata(data?.data);
      }
    };

    createsupport();
  }, []);

 

  


  // =============================================get support api=================================================

  const { isPending, data, isError, isSuccess, refetch } = useQuery({
    queryKey: ["getSupportChat"],
    queryFn: getSupportChat,
  });

  // {
  //   isSuccess && dispatch(address(addressData));
  // }

  {
    isError && toast.error("Something went wrong");
  }
  console.log(data, "data");

  const handleSend = () => {
    if (message == "") {
      toast.error("Please Enter Message");
    } else {
      socket.emit("send_support", {
        message: message,
        sender_id: createsupportdata?.user_id,
        sender_type: 1,
        support_id: createsupportdata?._id,
      });
      refetch();
      setMessage("");
    }
  };

   
  useEffect(() => {
   
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [data]);


    // ========================listen socket=========================================

    useEffect(() => {
      socket?.on("support_id", () => {
        refetch();
      });
  
      return () => {
        socket?.off("support_id");
      };
    }, [socket, refetch]);

  return (
    <Layout>
      <section className="our-products-sec">
        <Container>
          <Row className="">
            <h2>Support</h2>
            <Col lg={12}>
              <div className="our-cart-card">
                <Row>
                  <Col lg={12}>
                    <div   className="support-chat-box-main">
                      <div className="support-inner">
                        {/* <h2>Chat</h2> */}
                        <div fluid ref={chatContainerRef} className="support-msg-box">
                          {data?.data?.length > 0 && data?.data?.map((msg) => {
                            if (msg?.docModel == "User") {
                              return (
                                <div className="right-chat-part">
                                  <div className="user-box">
                                    <div className="user-top-hdng">
                                      <h4>{moment(msg?.createdAt).format("hh:mm a")}</h4>
                                      <h3>{userData?.data?.full_name}</h3>
                                      <img
                                        src={
                                         `${BASE_URL}${userData?.data?.profile_image}`
                                        }
                                      />
                                    </div>
                                    <div className="user-msg-box">
                                      <p>{msg?.message}</p>
                                    </div>
                                  </div>
                                </div>
                              );
                            } else {
                              return (
                                <div className="user-box">
                                  <div className="user-top-hdng">
                                    <img
                                      src={
                                        require("../Assets/images/place_holder.png")
                                          
                                      }
                                    />
                                    <h3>Admin</h3>
                                    <h4>{moment(msg?.createdAt).format("hh:mm a")}</h4>
                                  </div>
                                  <div className="user-msg-box">
                                    <p>{msg?.message}</p>
                                  </div>
                                </div>
                              );
                            }
                          })}

                          {data?.data?.length == 0 && (
                        <div style={{ textAlign: "center" }}>
                          <p>No support created yet!</p>
                        </div>
                      )}
                        </div>
                      </div>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSend();
                        }}
                      >
                        <div class="send-box">
                          <div class="mb-3 input-group chat-msg-bar">
                            <input
                              placeholder="Type message.."
                              aria-label="send"
                              aria-describedby="basic-addon2"
                              name="message"
                              type="text"
                              class="send-feild form-control"
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                            />
                            <button
                              class="input-group-text"
                              id="basic-addon2"
                              type="submit"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="18"
                                viewBox="0 0 16 18"
                                fill="none"
                              >
                                <path
                                  d="M0.523716 17.2572C0.110234 16.7692 -0.162488 16.0958 0.108768 15.3141C0.544243 14.0606 1.01344 12.8205 1.47531 11.576C1.69671 10.9782 1.93864 10.3879 2.16005 9.79155C2.20697 9.66398 2.26562 9.62838 2.39611 9.62986C4.24651 9.63431 6.09838 9.63134 7.94879 9.63431C8.17312 9.63431 8.3696 9.58536 8.51476 9.39994C8.67018 9.20266 8.70244 8.98609 8.60273 8.75765C8.50743 8.5396 8.33588 8.41055 8.09834 8.38533C8.02796 8.37791 7.95612 8.38088 7.88574 8.38088C6.06026 8.38088 4.23479 8.3794 2.41077 8.38533C2.26268 8.38533 2.19964 8.33786 2.14832 8.20139C1.4929 6.46736 0.828695 4.73629 0.174749 3.00225C-0.361897 1.57972 0.531047 0.117134 1.99729 0.00588286C2.4489 -0.0282342 2.86531 0.0874672 3.25533 0.315903C7.12476 2.57505 10.9956 4.83271 14.8651 7.09185C16.1803 7.86022 16.3914 9.58388 15.2932 10.6059C15.1671 10.7246 15.0205 10.8254 14.8709 10.913C11.0015 13.1736 7.13209 15.4327 3.2612 17.6904C2.36239 18.211 1.33308 18.0805 0.523716 17.2572Z"
                                  fill="white"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}
