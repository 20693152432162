import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { forgetPassword,resetPassword } from "../Redux/Actions/userAction";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";

export default function ResetPassword() {
    const navigate=useNavigate()

  // ===========================================userData from redux=======================================

  const userData = useSelector((state) => state?.userData?.userData);

  console.log(userData, "userfromredux");

    // ===========================================POST DATA USING REACT QUERY==================================

    const { isPending, mutate } = useMutation({
        mutationFn: resetPassword,
        onSuccess: (res) => {
          if (res?.success) {
            toast.success(res?.message);
            console.log(res);
    
            navigate("/password-changed");
          } else {
            toast.error(res?.message);
          }
        },
        onError: (res) => {
          toast.error(res?.message || "Something went wrong");
        },
      });

  //    ==========================================react state================================================

  const [newPassword, setnewPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");

  const resetpassword = () => {
    if (!newPassword) {
      toast.error("Please enter new password");
    } else if (!confirmPassword) {
      toast.error("Please confirm your password");
    } else if (confirmPassword !== newPassword) {
      toast.error("Password did not match");
    } else {
      mutate({
        userId: userData?.data?._id,
        password: newPassword,
      });

    
    }
  };



  return (
    <>
      <div className="sign-up-flow">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="logo-part">
                <img
                  src={require("../Assets/images/logo-main-sign.svg").default}
                />
                {/* <img
                  src={require("../Assets/Images/cake-r-b.png")}
                  className="cake-r-b"
                /> */}
              </div>
            </Col>
            <Col lg={6}>
              <div className="sign-up-feilds">
                <Form>
                  <h2 className="mb-2">Reset Password</h2>
                  <p>
                    Your new password must be unique from those <br />
                    perviously used.
                  </p>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      type="password"
                      onChange={(e) => setnewPassword(e.target.value)}
                      value={newPassword}
                      name="newPassword"
                      placeholder="Enter here..."
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      onChange={(e) => setconfirmPassword(e.target.value)}
                      value={confirmPassword}
                      name="confirmPassword"
                      placeholder="Enter here..."
                    />
                  </Form.Group>
                  {/* <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Example textarea</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group> */}
                  <Button
                    type="button"
                    onClick={resetpassword}
                    className="mt-2"
                  >
                   {isPending ? "Submitting..." :"Reset"}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
