// src/components/GoogleLogin.js
import React, { useState } from "react";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "./Firebase";

import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { socialLogin, userRegister } from "../../Redux/Actions/userAction";
import { registrationData } from "../../Redux/Reducers/registrationSlice";
import { userdata } from "../../Redux/Reducers/userDataSlice";

const GoogleLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [registeredUser, setRegisteredUser] = useState(null);

  // =================================post data using react-query=====================

  const {
    isError,
    isSuccess,
    mutate: socialRegisterMutate,
  } = useMutation({
    mutationFn: userRegister,
    onSuccess: (res) => {
      console.log(res, "registerApi");
      if (res?.success) {
        // dispatch(registrationData(res?.data));
        toast.success(res?.message);
        // navigate("/otp-verification");
      } else {
        toast.error(res?.message);
      }
      // toast.success(res?.message);
    },
    onError: (res) => {
      toast.error("Something went wrong");
    },
  });

  // ===========================================POST DATA USING REACT QUERY==================================

  const { isPending, mutate } = useMutation({
    mutationFn: socialLogin,
    onSuccess: (res) => {
      console.log(res, "social");
      if (res?.success) {
        sessionStorage.setItem("token", res?.data?.token);
        dispatch(userdata(res));

        if (res?.success) {
        
          if (res?.data?.is_profile_created == 0) {
            window.location.href = "/profile-creation";
          } else {
            window.location.href = "/";
            toast.success(res?.message);
          }
        } else {
          toast.error(res?.message);
        }
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log("hii");
    try {
      console.log("first");
      const result = await signInWithPopup(auth, googleProvider);
      console.log("User Info: ", result?.user);
      setRegisteredUser(result?.user);
      if (result?.user) {
        mutate({
          social_id: result?.user?.uid,
          full_name: result?.user?.displayName,
          login_type: 0,
          device_token: result?.user?.accessToken,
          device_type: 2,
          email: result?.user?.email,
        });
      }
    } catch (error) {
      console.error("Error during login: ", error.message);
    }
  };

  return (
    <div>
      <div style={{ cursor: "pointer" }} onClick={handleLogin}>
        <div className="connect-google">
          <Link to="">
            <img src={require("../../Assets/images/google-icon.png")} />
            Continue with Google
          </Link>
        </div>
      </div>
    </div>
  );
};

export default GoogleLogin;
