import axios from "axios";
import toast from "react-hot-toast";

export const Api = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_BASE_URL,
  headers: {
    Authorization: sessionStorage.getItem("token"),
  },
});

Api.interceptors.response.use(
  (response) => {

    console.log(response,"ppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppppp")
    if(response?.data?.status==403){
      window.location.href = "/"
      sessionStorage.clear();
      localStorage.clear()

      toast.error(response?.data?.message);
    }else{
      return response;

    }
    
  },
  (error) => {
    // if (error?.response?.status === 401) {
    // //   setTimeout(() => {
    //     sessionStorage.clear();
    //     window.location.reload(false);
    //     window.location.href = "/";
    // //   }, 1000);
    // }

    console.log(error,"====================================================================================")
    return error.response;
  }
);
