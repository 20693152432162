import React from "react";
import Form from "react-bootstrap/Form";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Icons from "../Assets/Icon";

export default function PasswordChanged() {

  const navigate=useNavigate()
  return (
    <>
      <div className="sign-up-flow">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="logo-part">
                <img
                  src={require("../Assets/images/logo-main-sign.svg").default}
                />
                {/* <img
                  src={require("../Assets/Images/cake-r-b.png")}
                  className="cake-r-b"
                /> */}
              </div>
            </Col>
            <Col lg={6}>
              <div className="sign-up-feilds">
                <Form>
                  <div className="sign-icon-add">{Icons.Complete}</div>
                  <h2 className="mb-2">Password Changed!</h2>
                  <p>
                    Your Password has been changed
                    <br />
                    successfully!
                  </p>
                  <Button type="button" onClick={()=>navigate("/login")} className="mt-2">
                    Back to Login
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
