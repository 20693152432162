import { createSlice } from "@reduxjs/toolkit";



export const registrationSlice = createSlice({
    name:"registrationSlice",
    initialState:{
        registrationData:null
    },
    reducers:{
        registrationData:(state,action)=>{
            console.log(action)
            state.registrationData = action?.payload
        }
    }
})


export const {registrationData} = registrationSlice.actions

export default registrationSlice.reducer