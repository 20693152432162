import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Col, Container, Row, Button, InputGroup } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Icons from "../Assets/Icon";
import Layout from "../Components/Layouts/Layout";
import {
  addTocart,
  getAddresses,
  getProductbyId,
} from "../Redux/Actions/userAction";
import { useMutation, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { address } from "../Redux/Reducers/addressSlice";
import { notifycartcount } from "../Redux/Reducers/productSlice";

export default function OurProducts() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  // ===========================================get product Id from redux===========================

  const productId = useSelector((state) => state.productData.id);

  console.log(productId, "pid");

  // ==========================================react state=========================================

  const [count, setCount] = useState(1);

  const increment = () => {
    setCount(count + 1);
  };
  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  // =========================================api for get product by id==============================

  const { isPending, data, isSuccess, isError, refetch } = useQuery({
    queryKey: ["getAddresses"],
    queryFn: () => getProductbyId(productId),
    enabled: !!productId,
  });

  // {
  //   isSuccess && dispatch(address(addressData));
  // }

  {
    isError && toast.error("Something went wrong");
  }

  useEffect(() => {
    refetch();
  }, [productId]);

  // ==================================add cart=================================================

  const { isPending: addtocartPending, mutate } = useMutation({
    mutationFn: addTocart,
    onSuccess: (res) => {
      if (res?.success) {
        toast.success(res?.message);
        console.log(res);
        navigate("/add-to-cart");
        dispatch(notifycartcount(true));
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error("Something went wrong");
    },
  });

  

  // =========================================add to cart===========================================

  const addToCart = () => {
    mutate({
      productId: productId,
      quantity: count,
    });
  };

  return (
    <Layout>
      <section className="our-products-sec">
        <Container>
          <Row className="">
            <Col lg={12}>
              <div className="our-products-card">
                <Row className="align-items-center">
                  <Col lg={6}>
                    <div className="our-products-img">
                      {data?.data?.image && (
                        <img src={`${BASE_URL}/${data?.data?.image}`} />
                      )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="our-products-add">
                      <h2>{data?.data?.product_name}</h2>
                      <p>{data?.data?.desc}</p>
                      <div className="product-price">
                        ${data?.data?.price}
                        <span>/{data?.data?.quantity}L</span>
                      </div>
                      <div className="add-minus">
                        <p style={{ cursor: "pointer" }} onClick={decrement}>
                          -
                        </p>
                        <div className="">{count}</div>
                        <p style={{ cursor: "pointer" }} onClick={increment}>
                          +
                        </p>
                      </div>
                      <div onClick={addToCart}>
                        <Link
                          // to="/add-to-cart"
                          className=""
                        >
                          Add to Cart
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}
