import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { forgetPassword, resetPassword } from "../Redux/Actions/userAction";
import { useMutation } from "@tanstack/react-query";
import { registrationData } from "../Redux/Reducers/registrationSlice";
import { useDispatch } from "react-redux";

export default function ForgotPassword() {

  const navigate=useNavigate()
  const dispatch=useDispatch()

  const [email, setEmail] = useState("");


   // ===========================================POST DATA USING REACT QUERY==================================

   const { isPending, mutate } = useMutation({
    mutationFn: forgetPassword,
    onSuccess: (res) => {
      if (res?.success) {
        toast.success(res?.message);
        console.log(res);
        dispatch(registrationData(res?.data))

        navigate("/otp-verification",{
          state:{
            purpose:"forgetPass"
          }
        });
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res?.message || "Something went wrong");
    },
  });

  

  const sendOTP = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!email) {
      toast.error("Please enter your email");
    } else if (!emailRegex.test(email)) {
      toast.error("Please Enter a Valid Email");
    } else {
      mutate({
        email,
      });
    }
  };

 

  return (
    <>
      <div className="sign-up-flow respo-height-100vh">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="logo-part">
                <img
                  src={require("../Assets/images/logo-main-sign.svg").default}
                />
                {/* <img
                  src={require("../Assets/Images/cake-r-b.png")}
                  className="cake-r-b"
                /> */}
              </div>
            </Col>
            <Col lg={6}>
              <div className="sign-up-feilds">
                <Form>
                  <h2 className="mb-2">Forgot Password</h2>
                  <p>
                    Don’t worry! It occurs. <br />
                    Please enter the email address linked with your account.
                  </p>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      name="email"
                      placeholder="Enter here..."
                    />
                  </Form.Group>
                  {/* <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Example textarea</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group> */}
                  <Button type="button" onClick={sendOTP} className="mt-2">
                    Send OTP
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
