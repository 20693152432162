// src/Utils/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import toast from "react-hot-toast";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAIvbq1HohOklAli4JRR0SD537lkHvBrhw",
  authDomain: "just-in-3c664.firebaseapp.com",
  projectId: "just-in-3c664",
  storageBucket: "just-in-3c664.appspot.com",
  messagingSenderId: "728203787055",
  appId: "1:728203787055:web:0bd9a7a0ce083bda3e7fda"
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

googleProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
googleProvider.addScope('https://www.googleapis.com/auth/calendar.events.readonly'); // Add more scopes as needed

const requestPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");
      const currentToken = await getToken(messaging, {
        vapidKey: "BLFO5sxyYak23Daz-3UtpJ5qzjrqdSp9r-fhohyPJXje35qZGKbaJ1iiGu_Sn9L6IMpJ571rXJWYXZW_nla-iks",
        serviceWorkerRegistration: await navigator.serviceWorker.register('/firebase-messaging-sw.js')
      });
      if (currentToken) {
        console.log("FCM Token:", currentToken);
        sessionStorage.setItem("FCM Token", currentToken)
        // Send the token to your server and save it
      } else {
        console.error("No registration token available. Request permission to generate one.");
      }
    } else {
      console.error("Unable to get permission to notify.");
    }
  } catch (error) {
    console.error("Error requesting permission or getting token:", error);
  }
};


requestPermission();


onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
  toast.success(payload?.data?.title)
  // Customize the UI to display the notification content
});


export { auth, googleProvider };
