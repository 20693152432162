import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Col, Container, Row, Button, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Layout from "../Components/Layouts/Layout";
import { useDispatch } from "react-redux";
import {
  addAddresses,
  createPaymentIntent,
  getAddresses,
  getCart,
  placeOrder,
  removeFromCart,
} from "../Redux/Actions/userAction";
import { address } from "../Redux/Reducers/addressSlice";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "@tanstack/react-query";
import { computeHeadingLevel } from "@testing-library/react";
import CartItem from "../Components/CartItem";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment/moment";
import { loadStripe } from "@stripe/stripe-js";
import { paymentIntent } from "../Redux/Reducers/paymentSlice";
import { notifycartcount } from "../Redux/Reducers/productSlice";

export default function AddToCart() {
  const dispatch = useDispatch();

  const [showAddress, setshowAddress] = useState(false);
  const handleClose = () => setshowAddress(false);
  const handleShow = () => setshowAddress(true);

  const days = [
    {
      timeSlot: [
        "00:00 AM-02:00 AM",
        "02:00 AM-04:00 AM",
        "04:00 AM-06:00 AM",
        "06:00 AM-08:00 AM",
        "08:00 AM-10:00 AM",
        "10:00 AM-12:00 PM",
        "12:00 PM-14:00 PM",
        "14:00 PM-16:00 PM",
        "16:00 PM-18:00 PM",
        "18:00 PM-20:00 PM",
        "20:00 PM-22:00 PM",
        "22:00 PM-00:00 AM",
      ],
    },
  ];

  // ===============================================ready for checkout=======================================

  const [orderaddress, setorderaddress] = useState(null);
  const [instruction, setinstruction] = useState(null);
  const [value, onChange] = useState();
  const [summaryShow, setsummaryShow] = useState(false);

  const [isActive, setisActive] = useState(null);
  const [time, setTime] = useState(null);
  const [date, setdate] = useState(null);
  const [checkoutPrice, setcheckoutPrice] = useState(null);
  const [checkoutQauntity, setcheckoutQauntity] = useState(null);

  // Function to disable dates before today
  const tileDisabled = ({ date }) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    return date <= today || date <= tomorrow;
  };

  useEffect(() => {
    if (value) {
      setdate(moment(value).format("DD MMM YYYY"));
    }
  }, [value]);

  console.log(date, "date");

  const handlecheckout = () => {
    if (data?.data?.length == 0) {
      toast.error("Please add item in cart");
    } else if (!orderaddress) {
      toast.error("Please select yout address");
    } else if (!value) {
      toast.error("Please select a date");
    } else if (!date) {
      toast.error("Please select a date");
    } else if (value == new Date()) {
      toast.error("Selected data must be one day from tomorrow");
    } else if (!time) {
      toast.error("Please select time");
    } else {
      console.log(orderaddress, time, value, instruction, date);
      setsummaryShow(true);
    }
  };

  // ==============================================create payment intent=====================================

  const {
    isSuccess: paymentsuccess,
    data: paymentdata,
    isError: paymenterror,
  } = useQuery({
    queryKey: ["appointmentpayment"],
    queryFn: createPaymentIntent,
  });

  {
    paymentsuccess && dispatch(paymentIntent(paymentdata));
  }

  {
    paymenterror && toast.error(data?.message || "Something went wrong");
  }

  console.log(paymentdata, "data?.data?.session_id");

  const makePayment = async () => {
    // const stripe = await loadStripe(
    //   "pk_test_51O9nKhLytwmH9rwQT3cppqEVmCHTqhxIDBHGr31ZtothHR22Yy9K0vAGXAm6Zv2aQa2yyWT69i8tzpV2CJ2KXcRH00gBf0qg3V"
    // );

    // try {
    //   const result = await stripe.redirectToCheckout({
    //     // sessionId: paymentdata?.data?.session_id,
    //     sessionId:"cs_test_a1D0D86T9ObdLmWeehz7xNJeBEYB4FP0X1Nylt3YgttmVFCO1UCCI61QiC"
    //   });

    //   console.log(result); // Log the result for debugging
    // } catch (error) {
    //   console.error("Error redirecting to checkout:", error);
    // }

    placeordermutate({
      addressId: orderaddress,
      date: date,
      time: time,
      instructions: instruction,
    });

    window.location.href = paymentdata?.data?.session_url;

    // console.log("make");

    // const { error } = await stripe.redirectToCheckout({
    //   lineItems: [{ price: 'price_12345', quantity: 1 }],
    //   mode: 'payment',
    //   // successUrl: `${window.location.origin}/success`,
    //   // cancelUrl: `${window.location.origin}/cancel`,
    // });

    // if (error) {
    //   console.error("Error redirecting to checkout:", error);
    // }
  };

  // ===========================================api for ADD ADDRESS==================================

  const { isPending: placeorderpending, mutate: placeordermutate } =
    useMutation({
      mutationFn: placeOrder,
      onSuccess: (res) => {
        if (res?.success) {
          toast.success(res?.message);
          console.log(res);
          // setshowAddress(false);

          // navigate("/");
          //  handleCloseAddAddress();
          //  handleCloseMyAddress();
          // adressrefetch();
        } else {
          toast.error(res?.message);
        }
      },
      onError: (res) => {
        toast.error("Something went wrong");
      },
    });

  //  ===============================================add address==============================================

  const [house, setHouse] = useState();
  const [landmark, setLandMark] = useState();
  const [saveAs, setsaveAs] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [orderaddressname, setorderaddressname] = useState(null);

  const handleItemClick = (index, value) => {
    setActiveIndex(index); // Update active index on click
    setsaveAs(index);
  };

  // =========================================api for get address==============================

  const {
    isPending: addressPending,
    data: addressData,
    isError,
    isSuccess,
    refetch: adressrefetch,
  } = useQuery({
    queryKey: ["getAddresses"],
    queryFn: getAddresses,
  });

  {
    isSuccess && dispatch(address(addressData));
  }

  {
    isError && toast.error("Something went wrong");
  }

  // ===========================================api for ADD ADDRESS==================================

  const { isPending: addaddresspending, mutate: addaddressmutate } =
    useMutation({
      mutationFn: addAddresses,
      onSuccess: (res) => {
        if (res?.success) {
          toast.success(res?.message);
          console.log(res);
          setshowAddress(false);

          // navigate("/");
          //  handleCloseAddAddress();
          //  handleCloseMyAddress();
          adressrefetch();
        } else {
          toast.error(res?.message);
        }
      },
      onError: (res) => {
        toast.error("Something went wrong");
      },
    });

  // =========================================get cart==============================

  const {
    isPending,
    data,
    isError: carterror,
    isSuccess: cartsuccess,
    refetch,
  } = useQuery({
    queryKey: ["getCart"],
    queryFn: getCart,
  });

  {
    cartsuccess && dispatch(notifycartcount(false));
  }

  {
    carterror && toast.error("Something went wrong");
  }

  console.log(data, "dfghdfgdfgdf");

  // useEffect(()=>{
  //   refetch()

  // },[data])

  useEffect(() => {
    if (summaryShow && data?.data?.length > 0) {
      let checkoutPrice = data.data.reduce((acc, cur) => {
        return acc + cur?.productId?.price * cur?.quantity;
      }, 0);

      setcheckoutPrice(checkoutPrice);
    }
  }, [summaryShow, data]);

  useEffect(() => {
    if (summaryShow && data?.data?.length > 0) {
      let checkoutQuantity = data.data.reduce((acc, cur) => {
        return acc + cur?.quantity;
      }, 0);
      setcheckoutQauntity(checkoutQuantity);

      // setcheckoutPrice(checkoutPrice);
    }
  }, [summaryShow, data]);

  // =======================================when product inc price also inc===========================


  const handleapiresponse=()=>{
    refetch()
    
  }

  return (
    <Layout>
      <section className="our-products-sec">
        <Container>
          <Row className="">
            <h2>Cart</h2>
            {data?.data?.length == 0 ? (
              <div style={{ textAlign: "center", marginTop: "100px" }}>
                <h4>Your cart is empty</h4>
              </div>
            ) : (
              <>
                <Col lg={12}>
                  <div className="our-cart-card">
                    <div className="overflow-con-set">
                      <Row>
                        <Col md={11} sm={11} xs={11}>
                          <Row className="product-price-add-box mb-4">
                            <Col md={3} sm={3} xs={3}>
                              <h3>Product</h3>
                            </Col>
                            <Col md={3} sm={3} xs={3}>
                              <h3>Price</h3>
                            </Col>
                            <Col md={3} sm={3} xs={3}>
                              <h3>Quantity</h3>
                            </Col>
                            <Col md={3} sm={3} xs={3}>
                              <h3>Subtotal</h3>
                            </Col>
                            {/* <Col md={3}>
                      <h3>Action</h3>
                      </Col> */}
                          </Row>
                        </Col>
                      </Row>

                    {data?.data?.length > 0 &&
                      data?.data?.map((cartProduct, index) => {
                        return (
                          <CartItem
                            cartId={cartProduct?._id}
                            refetch={refetch}
                            cartProduct={cartProduct}
                            data={data?.data}
                            index={index}
                            handleapiresponse={handleapiresponse}
                          />
                        );
                      })}

                      {data?.data?.length == 0 && (
                        <div style={{ textAlign: "center" }}>
                          <p>No data updated yet!</p>
                        </div>
                      )}

                      {/* <div className="product-price-add-box mb-3">
                  <div className="product-price-list">
                    <img
                      src={require("../Assets/images/petrol-icon.svg").default}
                    />
                    <h4>Petrol</h4>
                  </div>
                  <div className="card-price">$650</div>
                  <div className="add-minus">
                    <p>-</p>
                    <div className="">1</div>
                    <p>+</p>
                  </div>
                  <div className="card-price">$650</div>
                </div> */}
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="our-cart-card">
                    <Row>
                      <Col lg={7}>
                        <div className="products-deliver-to">
                          <h3>Deliver to:</h3>
                          <div className="deliver-to-card">
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={handleShow}
                            >
                              <h4>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M7.57781 16C7.02191 15.7375 6.52768 15.4115 6.38188 14.7591C6.33959 14.5702 6.31977 14.3724 6.31889 14.1786C6.3136 12.8456 6.31625 11.5123 6.31625 10.1794C6.31625 10.0278 6.31625 9.87673 6.31625 9.6816C6.04622 9.6816 5.81144 9.6816 5.5771 9.6816C4.28602 9.6816 2.99494 9.68732 1.7043 9.67939C0.718926 9.67367 -0.0065626 8.94113 4.47684e-05 7.98042C0.00665213 7.04394 0.732581 6.32373 1.69814 6.31801C3.07335 6.31008 4.44856 6.3158 5.82378 6.3158C5.97442 6.3158 6.12463 6.3158 6.3158 6.3158C6.3158 5.88633 6.3158 5.49737 6.3158 5.10798C6.3158 3.97151 6.30964 2.8346 6.31801 1.69814C6.32506 0.732581 7.04394 0.00665213 7.98042 4.47685e-05C8.94113 -0.0065626 9.67455 0.718926 9.67939 1.70386C9.68688 3.21871 9.6816 4.734 9.6816 6.31625C9.8437 6.31625 9.99038 6.31625 10.1375 6.31625C11.3585 6.31625 12.5813 6.35897 13.7993 6.30303C14.8402 6.25546 15.5952 6.5845 16 7.57781C16 7.8584 16 8.13944 16 8.42003C15.5961 9.40981 14.845 9.74238 13.8055 9.69481C12.5897 9.63931 11.37 9.6816 10.1516 9.6816C10.0036 9.6816 9.85559 9.6816 9.6816 9.6816C9.6816 11.2057 9.68424 12.6739 9.67895 14.142C9.67807 14.349 9.65869 14.5605 9.6142 14.7627C9.4706 15.4155 8.97196 15.7357 8.42003 16C8.13944 16 7.8584 16 7.57781 16Z"
                                    fill="#171717"
                                  />
                                </svg>
                                Add New Address
                              </h4>
                            </div>
                            <div className="select-deliver-ad">
                              <Form>
                                {addressData?.data?.length > 0 &&
                                  addressData?.data?.map((address, index) => {
                                    return (
                                      <div
                                        key={`address-${index}`}
                                        className="mb-3"
                                      >
                                        <Form.Check
                                          onChange={(e) => {
                                            setorderaddress(e.target.value);
                                            setorderaddressname(
                                              address?.address
                                            );
                                          }}
                                          type="radio"
                                          label={
                                            address?.save_as === 0
                                              ? "Home"
                                              : address?.save_as === 0 ? "Other" : "Office"
                                          }
                                          id={`address-${index}`}
                                          value={address?._id}
                                          name="addressRadioGroup" // Ensure all radio buttons belong to the same group
                                        />
                                        <p>
                                          {address?.address} {address?.landmark}
                                        </p>
                                      </div>
                                    );
                                  })}

                                {/* {["radio"].map((type) => (
                              <div key={`default-${type}`} className="mb-3">
                                <Form.Check
                                  type={type}
                                  label="Work"
                                  id={`disabled-default-${type}`}
                                />
                                <p>
                                  Plot No.209, Kauri Hills, Madhapur, Telangana
                                  500033, Ph: +91 234567890
                                </p>
                              </div>
                            ))} */}
                              </Form>
                            </div>
                          </div>
                          <h3>Instructions:</h3>
                          <Form>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Form.Control
                                as="textarea"
                                rows={4}
                                placeholder="Enter here"
                                value={instruction}
                                name="instruction"
                                onChange={(e) => {
                                  setinstruction(e.target.value);
                                }}
                              />
                            </Form.Group>
                          </Form>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div className="products-deliver-to">
                          <h3>Schedule:</h3>
                          <div className="deliver-schedule-card">
                            <h5>Select Date</h5>
                            <div className="schedule-day-select mb-3">
                              {/* <div className="schedule-day-cell active">
                            <p>Tue</p>
                            <p>21</p>
                          </div>
                          <div className="schedule-day-cell">
                            <p>Wed</p>
                            <p>21</p>
                          </div>
                          <div className="schedule-day-cell">
                            <p>Thu</p>
                            <p>21</p>
                          </div>
                          <div className="schedule-day-cell">
                            <p>Fri</p>
                            <p>21</p>
                          </div>
                          <div className="schedule-day-cell">
                            <p>Sat</p>
                            <p>21</p>
                          </div>
                          <div className="schedule-day-cell">
                            <p>Sun</p>
                            <p>21</p>
                          </div> */}
                              <div
                                className="celender-box"
                                style={{ marginTop: "20px" }}
                              >
                                <Calendar
                                  onChange={onChange}
                                  value={value}
                                  tileDisabled={tileDisabled}
                                />
                              </div>
                            </div>

                            <h5>Select Time</h5>
                            <div className="schedule-day-select justify-content-between">
                              {days[0]?.timeSlot?.map((time, index) => {
                                return (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setisActive(index);
                                      setTime(time);
                                    }}
                                    className={`schedule-time-cell ${
                                      isActive == index ? "active" : ""
                                    }`}
                                  >
                                    <p
                                    // className={isActive ? "active" : ""}
                                    >
                                      {time}
                                    </p>
                                  </div>
                                );
                              })}

                              {/* <div className="schedule-time-cell">
                            <p>10:00 AM - 11:00 AM</p>
                          </div>
                          <div className="schedule-time-cell">
                            <p>10:00 AM - 11:00 AM</p>
                          </div>
                          <div className="schedule-time-cell">
                            <p>10:00 AM - 11:00 AM</p>
                          </div>
                          <div className="schedule-time-cell">
                            <p>10:00 AM - 11:00 AM</p>
                          </div>
                          <div className="schedule-time-cell">
                            <p>10:00 AM - 11:00 AM</p>
                          </div> */}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="product-checkout d-flex justify-content-center">
                          <Link
                            // onClick={handleShow}
                            onClick={handlecheckout}
                          >
                            Checkout
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Container>

        <Modal show={showAddress} onHide={handleClose}>
          {/* step one  */}

          <div className="">
            <Modal.Header closeButton>
              <Modal.Title>Add New Address</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="popup-common ">
                <img src={require("../Assets/images/map-img.png")} />
                <Form className="mt-3">
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="House/Flat Number*"
                      value={house}
                      name="house"
                      onChange={(e) => setHouse(e.target.value)}
                      autoFocus
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Landmark (Optional)"
                      autoFocus
                      value={landmark}
                      name="landmark"
                      onChange={(e) => setLandMark(e.target.value)}
                    />
                  </Form.Group>
                  {/* <Form.Select aria-label="Choose Delivery Zone">
                    <option>Choose Delivery Zone</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select> */}
                  <Form.Label>Save as</Form.Label>
                  <div className="add-address-pop">
                    <ul>
                      <li
                        value="Home"
                        className={activeIndex === 0 ? "active" : ""}
                        onClick={() => handleItemClick(0)}
                      >
                        Home
                      </li>
                      <li
                        value="Other"
                        className={activeIndex === 1 ? "active" : ""}
                        onClick={() => handleItemClick(1)}
                      >
                        Other
                      </li>
                      <li
                        value="Office"
                        className={activeIndex === 2 ? "active" : ""}
                        onClick={() => handleItemClick(2)}
                      >
                        Office
                      </li>
                    </ul>
                  </div>
                </Form>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button
                variant="primary"
                // onClick={handleClose}
                onClick={() => {
                  if (!house) {
                    toast.error("Please enter address");
                  } else {
                    addaddressmutate({
                      address: house,
                      landmark: landmark,
                      save_as: saveAs,
                    });
                  }
                }}
                className="theme-btn"
              >
                Save & Proceed
              </Button>
            </Modal.Footer>
          </div>
        </Modal>

        {/* step two  */}
        <Modal show={summaryShow} onHide={() => setsummaryShow(false)}>
          <div className="">
            <Modal.Header closeButton>
              <Modal.Title>Order Summary</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="order-summarys">
                <div className="order-summary-bill mb-3">
                  <h3>Billing Details</h3>
                  <ul>
                    <li>
                      <p>Price ({checkoutQauntity} items)</p>
                    </li>
                    <li>${checkoutPrice}</li>
                  </ul>
                  <ul>
                    <li>
                      <p>Quantity</p>
                    </li>
                    <li>{checkoutQauntity}</li>
                  </ul>
                  <ul>
                    <li>
                      <p>Tax</p>
                    </li>
                    <li>${(checkoutPrice * 0.03).toFixed(2)}</li>
                  </ul>
                  <ul>
                    <li>
                      <p>
                        <div>Grand Total</div>
                      </p>
                    </li>
                    <li>${checkoutPrice * 0.03 + checkoutPrice}</li>
                  </ul>

                  <ul className="border-top-ad-price">
                    <li>To Pay</li>
                    <li>${checkoutPrice * 0.03 + checkoutPrice}</li>
                  </ul>
                </div>
                <div className="order-summary-bill mb-3">
                  <h4>Home</h4>
                  <p>{orderaddressname}</p>
                </div>

                <div className="order-summary-bill mb-3">
                  <h3 className="m-0">
                    {moment(value).format("ddd")},{date} - {time}
                  </h3>
                </div>
                {instruction ? <div className="order-summary-bill mb-5">
                  <h4>Instructions</h4>
                  <p>{instruction}</p>
                </div> : ""

                }

                
                <div className="checkout-pay-card ">
                  <div onClick={makePayment}>
                    <Link>Checkout</Link>
                  </div>

                  <div className="checkout-pay-amout">
                    <p>Total Amt:</p>
                    <h4 className="m-0">
                      ${checkoutPrice * 0.03 + checkoutPrice}
                    </h4>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>

        <Modal>
          {/* step three  */}

          <div className="">
            <Modal.Header closeButton>
              <Modal.Title>Order Summary</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="order-summary">
                <div className="order-summary-bill mb-3">
                  <div className="order-package">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="86"
                      height="80"
                      viewBox="0 0 86 80"
                      fill="none"
                    >
                      <rect width="86" height="80" rx="10" fill="#209920" />
                      <path
                        d="M32.1449 34.9043C33.9408 35.5713 35.6675 36.2121 37.3991 36.8553C37.3991 43.2645 37.3991 49.6545 37.3991 56.1144C36.6926 55.8516 36.0151 55.5988 35.336 55.3475C30.4673 53.541 25.5977 51.7353 20.729 49.928C20.1078 49.6975 20.0008 49.5438 20.0008 48.8891C20 42.888 20 36.8868 20 30.8856C20 30.745 20 30.6052 20 30.3962C20.8449 30.7089 21.6269 30.9986 22.409 31.2883C23.5065 31.6947 24.6016 32.1081 25.7031 32.5039C25.9534 32.5938 26.0467 32.7175 26.0443 32.978C26.0306 34.3657 26.0371 35.7542 26.0387 37.1427C26.0395 37.6683 26.0934 37.7067 26.6083 37.5576C27.2585 37.3693 27.915 37.1988 28.5531 36.9805C28.8548 36.8768 29.0552 36.9352 29.2805 37.1242C29.9966 37.7251 30.7216 38.3175 31.4546 38.8992C31.6058 39.0191 31.807 39.0821 31.9856 39.1712C32.0387 38.9738 32.1345 38.7771 32.1369 38.5788C32.1522 37.3801 32.1449 36.1806 32.1449 34.9043Z"
                        fill="white"
                      />
                      <path
                        d="M46.7689 53.1898C44.1539 54.1619 41.5461 55.1308 38.8933 56.1167C38.8836 55.9369 38.8707 55.8001 38.8707 55.6641C38.8699 49.5354 38.8723 43.4066 38.8627 37.2787C38.8619 36.979 38.9432 36.8307 39.2513 36.717C44.7976 34.6677 50.3382 32.6053 55.8796 30.5468C55.989 30.506 56.1001 30.4722 56.2513 30.4215C56.2513 33.6027 56.2513 36.7431 56.2513 39.9258C52.7416 40.0464 49.8803 41.4004 47.8825 44.1842C45.8894 46.9605 45.6279 49.9879 46.7689 53.1898Z"
                        fill="white"
                      />
                      <path
                        d="M56.257 41.3258C61.0814 41.3212 65.0176 45.0787 64.9999 49.6714C64.9822 54.2556 61.0605 57.9969 56.2698 58C51.463 58.0031 47.5083 54.221 47.5276 49.6406C47.5461 45.0441 51.4453 41.3304 56.257 41.3258ZM54.4031 50.8017C53.7996 50.0801 53.2428 49.4155 52.6877 48.7493C52.3731 48.3712 51.9732 48.1622 51.463 48.246C50.4693 48.4097 50.0751 49.4247 50.7107 50.2108C51.5057 51.1951 52.3256 52.1602 53.1366 53.1322C53.8568 53.9959 54.564 54.0343 55.3831 53.2529C57.3005 51.4233 59.2163 49.593 61.1313 47.7619C61.2488 47.6497 61.3687 47.5368 61.4669 47.4092C61.9472 46.7807 61.6785 45.8909 60.9237 45.6035C60.3388 45.3806 59.8665 45.5743 59.4489 45.9754C58.4021 46.9797 57.3512 47.9794 56.302 48.9821C55.6833 49.5753 55.0653 50.1677 54.4031 50.8017Z"
                        fill="white"
                      />
                      <path
                        d="M44.1748 25.1626C42.6259 25.7366 41.1937 26.266 39.7614 26.7985C35.6724 28.3192 31.5833 29.8383 27.4974 31.3659C27.1724 31.4873 26.8924 31.5011 26.5552 31.372C24.7529 30.6797 22.9385 30.015 21.0613 29.3158C21.219 29.2382 21.3139 29.1798 21.4177 29.1414C26.8586 27.119 32.2994 25.0973 37.7443 23.0841C37.9285 23.0157 38.1772 22.9665 38.3493 23.028C40.2539 23.7096 42.1488 24.4157 44.1748 25.1626Z"
                        fill="white"
                      />
                      <path
                        d="M33.129 33.7994C34.8396 33.1639 36.4255 32.5738 38.0114 31.9844C41.9379 30.526 45.8661 29.0714 49.7886 27.6046C50.1193 27.4809 50.4001 27.4663 50.7365 27.5961C52.2041 28.1609 53.6822 28.6995 55.2399 29.2781C55.0581 29.3619 54.9494 29.4203 54.8344 29.4633C49.4338 31.4719 44.0316 33.4767 38.6334 35.4914C38.3035 35.6143 38.0235 35.6305 37.6839 35.4998C36.2163 34.9335 34.7366 34.3964 33.129 33.7994Z"
                        fill="white"
                      />
                    </svg>
                    <div className="">
                      <h3>Order Id #90897</h3>
                      <p className="m-0">Placed on June 19 2024</p>
                      <ul className="m-0">
                        <li>Petrol</li>
                        <li>Price: $600</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="order-summary-bill mb-3">
                  <h3>Billing Details</h3>
                  <ul>
                    <li>
                      <p>Price (3 items)</p>
                    </li>
                    <li>$600</li>
                  </ul>
                  <ul>
                    <li>
                      <p>Quantity</p>
                    </li>
                    <li>3</li>
                  </ul>
                  <ul>
                    <li>
                      <p>Tax</p>
                    </li>
                    <li>$600</li>
                  </ul>
                  <ul>
                    <li>
                      <p>
                        <div>Grand Total</div>
                      </p>
                    </li>
                    <li>$600</li>
                  </ul>

                  <ul className="border-top-ad-price">
                    <li>To Pay</li>
                    <li>$600</li>
                  </ul>
                </div>
                <div className="order-summary-bill mb-3">
                  <h4>Home</h4>
                  <p>
                    Plot No.209, Kauri Hills, Madhapur, Telangana 500033, Ph:
                    +91 234567890
                  </p>
                </div>

                <div className="order-summary-bill mb-3">
                  <h3 className="m-0">Sat,Apr 09 - 07:30 PM</h3>
                </div>

                <div className="order-summary-bill mb-4">
                  <h4>Instructions</h4>
                  <p>Drop with proper pacakging</p>
                </div>
                <div className="order-placed">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="99"
                    height="115"
                    viewBox="0 0 99 115"
                    fill="none"
                  >
                    <path
                      d="M98.9747 99.408L91.8924 25.2577C91.7407 23.6115 90.2483 22.365 88.5031 22.365H73.9338C73.7315 9.99489 62.8551 0 49.5 0C36.1449 0 25.2685 9.99489 25.0662 22.365H10.4969C8.72637 22.365 7.25933 23.6115 7.10756 25.2577L0.0252944 99.408C0.0252944 99.502 0 99.5961 0 99.6902C0 108.133 8.32166 115 18.5657 115H80.4343C90.6783 115 99 108.133 99 99.6902C99 99.5961 99 99.502 98.9747 99.408ZM49.5 6.34969C59.0864 6.34969 66.9021 13.499 67.1045 22.365H31.8955C32.0979 13.499 39.9136 6.34969 49.5 6.34969ZM80.4343 108.65H18.5657C12.141 108.65 6.93051 104.699 6.82933 99.8313L13.6081 28.7382H25.0409V38.3804C25.0409 40.1442 26.5585 41.5552 28.4555 41.5552C30.3526 41.5552 31.8702 40.1442 31.8702 38.3804V28.7382H67.1045V38.3804C67.1045 40.1442 68.6221 41.5552 70.5192 41.5552C72.4162 41.5552 73.9338 40.1442 73.9338 38.3804V28.7382H85.3666L92.1707 99.8313C92.0695 104.699 86.8337 108.65 80.4343 108.65Z"
                      fill="#209920"
                    />
                  </svg>
                  <h3>Your order was placed successfully !</h3>
                  <p>You will receive an order</p>
                  <p>confirmation email</p>
                </div>
              </div>
              <Modal.Footer className="justify-content-center">
                <Button
                  variant="primary"
                  onClick={handleClose}
                  className="theme-btn"
                >
                  Track Order
                </Button>
              </Modal.Footer>
            </Modal.Body>
          </div>
        </Modal>
      </section>
    </Layout>
  );
}
