import { createSlice } from "@reduxjs/toolkit";



export const productSlice = createSlice({
    name:"productSlice",
    initialState:{
        id:null,
        count:false
    },
    reducers:{
        productId:(state,action)=>{
            console.log(action)
            state.id = action?.payload
        },
        notifycartcount:(state,action)=>{
            console.log(action)
            state.count = action?.payload
        },
    }
})


export const {productId,notifycartcount} = productSlice.actions

export default productSlice.reducer