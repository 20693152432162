import { combineReducers, configureStore } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import storage from "redux-persist/lib/storage";
import {registrationSlice} from "./Reducers/registrationSlice";
import {userDataSlice} from "./Reducers/userDataSlice";
import {productSlice} from "./Reducers/productSlice";
import {paymentSlice} from "./Reducers/paymentSlice";


const rootReducer = combineReducers({
  registration:registrationSlice.reducer,
  userData:userDataSlice.reducer,
  productData:productSlice.reducer,
  paymentdata:paymentSlice.reducer

})

// Persist config
const persistConfig = {
    key: "root",
    storage: storage,
    // Optionally, you can blacklist or whitelist specific reducers
    // blacklist: ['meditationData'], // Reducers to exclude from persistence
    // whitelist: ['homedata', 'registerUserdata'], // Reducers to persist
  };

  const persistedReducer = persistReducer(persistConfig,rootReducer)


export const store = configureStore({
    reducer:persistedReducer

    
})

export const persistor = persistStore(store);