import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useMutation } from "@tanstack/react-query";
import { createProfile } from "../Redux/Actions/userAction";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { userdata } from "../Redux/Reducers/userDataSlice";


export default function ProfileCreation() {
  const navigate = useNavigate();
  const dispatch=useDispatch()

   // ===========================================userData from redux=======================================

   const userData = useSelector((state) => state?.userData?.userData);

   console.log(userData, "userfromredux");
 
  // ============================================React States==============================================

  const [profileDetails, setProfiledetails] = useState({
    name: "",
    address: "",
    landmark: "",
  });

  const [img, setImage] = useState();
  const [prev, setPrev] = useState();

  useEffect(()=>{
    if(userData?.data?.social_id){
      setProfiledetails((old)=>({...old,name:userData?.data?.full_name}))
    }


  },[userData])

  // ===========================================POST DATA USING REACT QUERY==================================

  const { isPending, mutate } = useMutation({
    mutationFn: createProfile,
    onSuccess: (res) => {
      if (res?.success) {
        toast.success(res?.message);
        console.log(res);
        dispatch(userdata(res))
        navigate("/");
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res?.message || "Something went wrong");
    },
  });

  // =============================================handlers===================================================

  const handlechange = (e) => {
    setProfiledetails((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  const handlepic = (e) => {
    setImage(e.target.files[0]);
    setPrev(URL.createObjectURL(e.target.files[0]));
  };
  console.log(img);
  console.log(prev);

  const handleSubmit = () => {
    let isvalid = true;
    if (!img) {
      toast.error("Please upload your profile picture");
      isvalid = false;
    } else if (!profileDetails?.name) {
      toast.error("Please enter your name");
      isvalid = false;
    } else if (!profileDetails?.address) {
      toast.error("Please enter your address");
      isvalid = false;
    } 
    if (isvalid) {
      console.log("yiipppeeee", profileDetails);
      let formdata = new FormData();
      formdata.append("name", profileDetails?.name);
      formdata.append("profile_image", img);
      formdata.append("is_profile_created", 1);
      formdata.append("address", profileDetails?.address);
      formdata.append("landmark", profileDetails?.landmark);

      mutate(formdata);
    }
  };

  return (
    <>
      <div className="sign-up-flow">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="logo-part">
                <img
                  src={require("../Assets/images/logo-main-sign.svg").default}
                />
                {/* <img
                  src={require("../Assets/Images/cake-r-b.png")}
                  className="cake-r-b"
                /> */}
              </div>
            </Col>
            <Col lg={6}>
              <div className="sign-up-feilds">
                <Form>
                  <div className="d-flex justify-content-center">
                    <div className="upld-img-box">
                      <Form.Control type="file" onChange={handlepic} />
                      <img
                        style={{ height: "200px" }}
                        src={
                          prev
                            ? prev
                            : require("../Assets/images/profile-img.svg")
                                .default
                        }
                      />
                      <p>Upload Photo</p>
                    </div>
                  </div>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Profile Name</Form.Label>
                    <Form.Control
                      value={profileDetails.name}
                      name="name"
                      onChange={handlechange}
                      type="text"
                      placeholder="Enter your first name"
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      value={profileDetails.address}
                      name="address"
                      onChange={handlechange}
                      type="text"
                      placeholder="House number/Flat/Block no"
                    />

                    {/* <GooglePlacesAutocomplete
                      // value={location}
                      selectProps={{
                        // value: location,
                        // onChange: handlePlaceSelect,
                        placeholder: "Search for a place...",
                      }}
                      // selectProps={{
                      //   location,
                      //   onChange: setlocation,
                      // }}
                      onError={(status, clearSuggestions) =>
                        console.log("Error occurred: ", status)
                      }
                      debounce={300}
                      searchOptions={{
                        types: ["address"],
                        componentRestrictions: { country: "in" }, // Adjust as needed
                      }}
                    /> */}
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Landmark</Form.Label>
                    <Form.Control
                      value={profileDetails.landmark}
                      name="landmark"
                      onChange={handlechange}
                      type="text"
                      placeholder="e.g., Near ABC School"
                    />
                  </Form.Group>

                  {/* <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Example textarea</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group> */}
                  <Button
                    onClick={handleSubmit}
                    type="button"
                    className="mt-4 mb-4"
                  >
                    {isPending ? "Submitting..." : "Submit"}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
