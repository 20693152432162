import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import OTPVerification from "./pages/OTPVerification";
import PasswordChanged from "./pages/PasswordChanged";
import Signup from "./pages/Signup";
import AllowLocation from "./pages/AllowLocation";
import ProfileCreation from "./pages/ProfileCreation";
import Home from "./pages/Home";
import OurProducts from "./pages/OurProducts";
import AddToCart from "./pages/AddToCart";
import Profile from "./pages/Profile";
import Support from "./pages/Support";
import Orders from "./pages/Orders";
import OrdersDetails from "./pages/OrdersDetails";
import ResetPassword from "./pages/ResetPassword";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import OrderPlaced from "./pages/orderPlaced";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="forgot-password" element={<ForgotPassword />}></Route>
        <Route path="otp-verification" element={<OTPVerification />}></Route>
        <Route path="password-changed" element={<PasswordChanged />}></Route>
        <Route path="reset-password" element={<ResetPassword />}></Route>

        <Route path="sign-up" element={<Signup />}></Route>
        <Route path="allow-location" element={<AllowLocation />}></Route>
        <Route element={<ProtectedRoutes/>}>
          <Route path="profile-creation" element={<ProfileCreation />}></Route>

          <Route path="our-products" element={<OurProducts />}></Route>
          <Route path="add-to-cart" element={<AddToCart />}></Route>
          <Route path="profile-view" element={<Profile />}></Route>
          <Route path="support" element={<Support />}></Route>
          <Route path="orders" element={<Orders />}></Route>
          <Route path="orders-placed" element={<OrderPlaced />}></Route>
          <Route path="orders-details/:id" element={<OrdersDetails />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
