import { createSlice } from "@reduxjs/toolkit";



export const paymentSlice = createSlice({
    name:"paymentSlice",
    initialState:{
        intent:null
    },
    reducers:{
        paymentIntent:(state,action)=>{
            console.log(action)
            state.intent = action?.payload
        }
    }
})


export const {paymentIntent} = paymentSlice.actions

export default paymentSlice.reducer