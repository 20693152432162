import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Icons from "../Assets/Icon";
import { addAddress, allowLocation } from "../Redux/Actions/userAction";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { userdata } from "../Redux/Reducers/userDataSlice";
import { useDispatch } from "react-redux";

export default function AllowLocation() {
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);
  const dispatch=useDispatch()
  const navigate=useNavigate()

  // ===================================================allow location==============================================

  const handleAllowLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          // handleClose();
        },
        (error) => {
          setError(error.message);
          // handleClose();
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
      // handleClose();
    }
  };

  // =============================================verifyOTP API==========================================

  const { isPending, isError, isSuccess, mutate } = useMutation({
    mutationFn: allowLocation,
    onSuccess: (res) => {
      console.log(res, "res");
      if (res?.success) {
        toast.success(res?.message);
        dispatch(userdata(res))
        // dispatch(userdata(res))
        // navigate("/allow-location")
        //
        // sessionStorage?.setItem("token", res?.data?.token);

        navigate("/profile-creation")
      } else {
        toast.error(res?.message);
      }
    },
    onError: (res) => {
      toast.error(res?.message);
    },
  });

  useEffect(() => {
    if (location) {
      mutate({
        lat: location?.latitude,
        long: location?.longitude,
      });
    }
  }, [location]);

  return (
    <>
      <div className="sign-up-flow respo-height-100vh">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="logo-part">
                <img
                  src={require("../Assets/images/logo-main-sign.svg").default}
                />
                {/* <img
                  src={require("../Assets/Images/cake-r-b.png")}
                  className="cake-r-b"
                /> */}
              </div>
            </Col>
            <Col lg={6}>
              <div className="sign-up-feilds">
                <Form>
                  <div className="sign-icon-add">{Icons.LocationIcon}</div>
                  <h2 className="mb-2">Allow Location</h2>
                  <p>
                    Your location details will help us to get in touch with you.
                  </p>
                  <Button
                    type="button"
                    onClick={handleAllowLocation}
                    className="mt-2"
                  >
                    Allow
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
