import { createSlice } from "@reduxjs/toolkit";



export const addressSlice = createSlice({
    name:"addressSlice",
    initialState:{
        address:null
    },
    reducers:{
        address:(state,action)=>{
            console.log(action)
            state.address = action?.payload
        }
    }
})


export const {address} = addressSlice.actions

export default addressSlice.reducer