import React from "react";
import Form from "react-bootstrap/Form";
import { Col, Container, Row, Button, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Icons from "../Assets/Icon";

export default function Footer() {
  const token = sessionStorage.getItem("token");

  return (
    <>
      <section className="footer-sec">
        {!token && (
          <div className="footer-top-contain">
            <Container>
              <div className="footer-top">
                <Row className="align-center">
                  <Col lg={2}></Col>
                  <Col lg={4}>
                    <h2 className="Platform-font">
                      {/* Stay Up to Date with All News and Exclusive Offers */}
                      Ready to get
                      <br /> started with Justin?
                    </h2>
                  </Col>
                  <Col lg={4}>
                    <div className="footer-form-input">
                      {/* <InputGroup>
                  <Form.Control
                    placeholder="Enter your email address"
                    aria-label="Enter your email address"
                    aria-describedby="basic-addon2"
                  />
                  <InputGroup.Text id="basic-addon2">
                    Register Now
                  </InputGroup.Text>
                </InputGroup> */}
                      <div className="Footer-action-sign">
                        <Link to="/sign-up">
                          Login/Sign Up
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="36"
                            height="36"
                            viewBox="0 0 36 36"
                            fill="none"
                          >
                            <circle
                              cx="17.5"
                              cy="17.5"
                              r="17.5"
                              transform="matrix(-1 0 0 1 35.5 0.5)"
                              fill="url(#paint0_radial_422_1549)"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M17.34 18.493V18.139C17.35 16.735 17.43 15.482 17.58 14.688C17.58 14.673 17.74 13.886 17.84 13.624C18 13.245 18.29 12.923 18.65 12.719C18.94 12.574 19.2401 12.5 19.5601 12.5C19.8101 12.512 20.22 12.637 20.51 12.742L20.76 12.836C22.37 13.478 25.46 15.577 26.65 16.86L26.74 16.95L27.12 17.37C27.37 17.691 27.5 18.085 27.5 18.508C27.5 18.887 27.38 19.266 27.15 19.572C27.08 19.671 26.97 19.798 26.87 19.906L26.49 20.303C25.19 21.624 22.37 23.478 20.9 24.092C20.9 24.106 19.9901 24.486 19.5601 24.5H19.5C18.84 24.5 18.22 24.121 17.9 23.509C17.81 23.341 17.73 23.013 17.66 22.725L17.55 22.181C17.42 21.307 17.34 19.965 17.34 18.493ZM11 20.018C10.17 20.018 9.5 19.339 9.5 18.5C9.5 17.662 10.17 16.983 11 16.983L14.7 17.31C15.35 17.31 15.88 17.842 15.88 18.5C15.88 19.158 15.35 19.691 14.7 19.691L11 20.018Z"
                              fill="white"
                            />
                            <defs>
                              <radialGradient
                                id="paint0_radial_422_1549"
                                cx="0"
                                cy="0"
                                r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(17.5 15.6037) scale(31.15 62.2196)"
                              >
                                <stop stop-color="#209920" />
                                <stop offset="1" stop-color="#182D09" />
                              </radialGradient>
                            </defs>
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </Col>
                  <Col lg={2}></Col>
                </Row>
              </div>
            </Container>
          </div>
        )}

        <Container>
          <Row className="align-items-baseline">
            <Col lg={3}>
              <div className="footer-first-box">
                <div className="footer-logo">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="132"
                    height="150"
                    viewBox="0 0 132 150"
                    fill="none"
                  >
                    <path
                      d="M84.8471 73.3374C86.0238 66.9427 84.8485 60.9917 82.3571 55.1476C79.2288 47.8032 75.2117 40.9473 70.7214 34.3849C67.2301 29.2826 63.6321 24.253 60.0352 19.2233V0.00967454C60.1443 -9.82232e-05 60.2535 -0.00178318 60.3611 0.00158674C61.0879 0.0292201 61.7967 0.360146 62.2742 0.982739C62.279 0.98931 62.2856 0.997398 62.2905 1.00549C62.8463 1.74535 63.2373 2.60755 63.7733 3.36544C70.0941 12.2937 76.5486 21.1323 82.726 30.1602C88.1359 38.0665 92.9756 46.3267 96.7446 55.1751C99.7462 62.216 101.162 69.3857 99.7445 77.09C96.2232 96.2293 79.2211 110.096 60.3611 110.026C60.252 110.026 60.1428 110.024 60.0352 110.023V99.7184C72.3277 96.6522 82.426 86.4961 84.8471 73.3374Z"
                      fill="white"
                    />
                    <path
                      d="M33.4516 81.8648C37.7138 91.4857 45.1377 97.317 55.5182 99.0776C56.9538 99.3214 58.3835 99.448 59.8014 99.4645C59.8787 99.4657 59.9572 99.4669 60.0357 99.4669V20.3405C59.9584 20.3381 59.8799 20.3393 59.8014 20.3464C59.4066 20.3804 59.0174 20.541 58.7034 20.8293C58.5499 20.9711 58.4034 21.1187 58.2769 21.2805C50.5986 31.0807 43.3904 41.1998 37.4443 52.1687C35.5388 55.6838 33.897 59.3175 32.5517 63.0941C30.2746 69.4892 30.7574 75.7826 33.4516 81.8648Z"
                      fill="white"
                    />
                    <path
                      d="M70.0393 61.2847C69.9592 61.7085 69.6647 61.9344 69.1861 61.9359C67.9048 61.9388 66.622 61.9359 65.3407 61.9359C64.9913 61.9374 64.7089 62.2198 64.7089 62.5678V74.8574C64.7089 75.635 64.3804 75.8252 63.5034 75.8252C62.3441 75.8252 61.192 75.8237 60.0342 75.8222V51.0229C60.0714 51.0229 60.11 51.0363 60.1396 51.0631C60.3477 51.24 60.5901 51.3812 60.7818 51.5744C63.7666 54.5703 66.7454 57.5705 69.7272 60.5679C69.9265 60.7688 70.0958 60.9799 70.0393 61.2847Z"
                      fill="white"
                    />
                    <path
                      d="M60.0359 51.0229V75.8222C58.8927 75.8207 57.7454 75.8192 56.5843 75.8192C55.7043 75.8192 55.3551 75.6512 55.3536 74.8929V62.5796C55.3536 62.2242 55.0667 61.9358 54.7114 61.9358C53.436 61.9358 52.1621 61.9373 50.8882 61.9358C50.4037 61.9342 50.0855 61.7142 50.0349 61.295C50.0141 61.1196 50.0855 60.9233 50.1524 60.7494C50.1925 60.6439 50.2981 60.5607 50.3828 60.4759C53.3319 57.5262 56.2811 54.575 59.2345 51.6282C59.4426 51.4201 59.6983 51.258 59.9214 51.0647C59.954 51.0363 59.9958 51.0229 60.0359 51.0229Z"
                      fill="url(#paint0_linear_387_4394)"
                    />
                    <path
                      d="M0.875166 147.935C0.14843 147.51 -0.125647 146.996 0.052752 146.393L0.916438 143.575C1.16323 142.766 1.80726 142.684 2.84918 143.328C3.74017 143.891 4.73393 144.172 5.83077 144.172C7.33832 144.172 8.32177 143.651 8.78116 142.609C9.24021 141.567 9.46999 139.832 9.46999 137.405V120.377C9.46999 119.349 9.94961 118.834 10.9093 118.834H14.0346C14.9938 118.834 15.4739 119.349 15.4739 120.377V136.994C15.4739 142.04 14.6272 145.306 12.9345 146.794C11.2415 148.282 8.89437 149.025 5.89242 149.025C3.79493 149.025 2.12244 148.662 0.875166 147.935Z"
                      fill="white"
                    />
                    <path
                      d="M21.252 139.36V127.966C21.252 127.144 21.6632 126.732 22.4856 126.732H25.7753C26.5977 126.732 27.0091 127.144 27.0091 127.966V137.879C27.0091 140.868 27.2148 142.753 27.626 143.535C28.0372 144.316 28.9009 144.707 30.2167 144.707C31.6284 144.707 32.7008 144.148 33.4343 143.03C34.1678 141.913 34.5345 139.593 34.5345 136.069V127.966C34.5345 127.144 34.9429 126.732 35.7596 126.732H39.0257C39.8422 126.732 40.2505 127.144 40.2505 127.966V147.092C40.2505 147.915 39.8393 148.326 39.0169 148.326H35.7681C34.9457 148.326 34.5344 147.915 34.5344 147.092V145.9C33.895 146.983 33.0819 147.727 32.095 148.131C31.108 148.535 29.9403 148.737 28.5923 148.737C26.4949 148.737 24.7472 148.066 23.3491 146.722C21.9511 145.379 21.252 142.925 21.252 139.36Z"
                      fill="white"
                    />
                    <path
                      d="M45.4112 146.887C44.7394 146.407 44.5544 145.893 44.8561 145.344L45.9048 143.432C46.2749 142.746 46.8507 142.664 47.632 143.185C48.4133 143.706 49.3351 144.09 50.3976 144.337C51.4596 144.583 52.4295 144.707 53.3069 144.707C53.9238 144.707 54.4994 144.645 55.0341 144.522C55.5686 144.398 56.0211 144.159 56.391 143.802C56.7612 143.445 56.9461 142.925 56.9461 142.239C56.9461 141.831 56.8363 141.484 56.6171 141.198C56.3978 140.913 56.1166 140.695 55.7742 140.546C55.4313 140.396 55.068 140.273 54.6844 140.178C54.1772 140.057 53.4472 139.855 52.4946 139.572C51.5418 139.29 50.6267 138.96 49.7497 138.583C49.0229 138.264 48.3136 137.817 47.6215 137.242C46.9292 136.667 46.4219 136.026 46.1 135.319C45.7777 134.613 45.6167 133.745 45.6167 132.717C45.6167 131.332 46.0176 130.119 46.8197 129.077C47.6215 128.035 48.6599 127.315 49.9346 126.917C51.2096 126.52 52.7995 126.321 54.7049 126.321C55.9933 126.321 57.2475 126.506 58.4678 126.876C59.6876 127.246 60.5579 127.596 61.0791 127.925C61.9015 128.446 62.1072 129.002 61.696 129.591L60.4418 131.421C60.0579 131.97 59.441 132.011 58.5913 131.545C57.9469 131.188 57.313 130.9 56.6894 130.681C56.0656 130.462 55.37 130.352 54.6023 130.352C54.0813 130.352 53.581 130.407 53.1014 130.516C52.6214 130.626 52.2136 130.832 51.8778 131.133C51.5419 131.435 51.3741 131.832 51.3741 132.326C51.3741 132.847 51.5283 133.255 51.8367 133.55C52.1452 133.844 52.5015 134.06 52.906 134.197C53.3103 134.335 53.6975 134.465 54.0677 134.588C54.8352 134.835 55.6097 135.072 56.391 135.298C57.1724 135.524 57.9672 135.795 58.7761 136.11C59.475 136.385 60.1538 136.775 60.8116 137.283C61.4696 137.79 61.9492 138.434 62.2509 139.216C62.5523 139.997 62.7032 140.888 62.7032 141.889C62.7032 143.219 62.3055 144.453 61.5107 145.591C60.7156 146.729 59.6669 147.538 58.3649 148.018C57.0625 148.497 55.5479 148.737 53.8207 148.737C51.8879 148.737 50.2429 148.6 48.886 148.326C47.529 148.052 46.3706 147.572 45.4112 146.887Z"
                      fill="white"
                    />
                    <path
                      d="M65.7873 130.845C64.9783 130.845 64.5742 130.503 64.5742 129.817V127.76C64.5742 127.075 64.9782 126.732 65.7873 126.732H67.1855V126.012C67.1855 125.395 67.3295 124.833 67.6173 124.326L69.2006 121.55C69.502 121.029 69.8586 120.768 70.2698 120.768H71.709C72.5314 120.768 72.9426 121.179 72.9426 122.002V126.732H76.2736C77.0822 126.732 77.4868 127.075 77.4868 127.76V129.817C77.4868 130.503 77.0822 130.845 76.2736 130.845H72.9426V141.046C72.9426 142.445 73.0418 143.449 73.2408 144.059C73.4394 144.669 73.9569 144.974 74.7933 144.974C75.218 144.974 75.698 144.902 76.2325 144.758C76.7672 144.614 77.1851 144.474 77.4868 144.336C77.9664 144.117 78.2816 144.275 78.4326 144.809L79.0495 147.154C79.1728 147.62 78.9944 147.963 78.5149 148.182C78.0625 148.388 77.4422 148.576 76.654 148.748C75.8656 148.919 75.074 149.005 74.2792 149.005C72.3188 149.005 70.6465 148.6 69.2621 147.791C67.8774 146.983 67.1853 145.317 67.1853 142.794V130.845H65.7873V130.845Z"
                      fill="white"
                    />
                    <path
                      d="M84.3749 123.976C83.415 123.976 82.9355 123.462 82.9355 122.434V119.678C82.9355 118.65 83.4151 118.136 84.3749 118.136H87.5825C88.5417 118.136 89.0217 118.65 89.0217 119.678V122.434C89.0217 124.203 88.9049 125.687 88.6723 126.886C88.439 128.086 87.8426 129.056 86.8834 129.796C86.1843 130.331 85.6358 130.386 85.2384 129.961L84.4571 129.118C83.9637 128.597 84.0388 128.083 84.6832 127.575C85.1217 127.233 85.4163 126.787 85.5672 126.238C85.718 125.69 85.7935 124.936 85.7935 123.976H84.3749Z"
                      fill="white"
                    />
                    <path
                      d="M96.5682 148.326C95.6083 148.326 95.1289 147.812 95.1289 146.783V120.377C95.1289 119.349 95.6085 118.834 96.5682 118.834H99.6935C100.653 118.834 101.133 119.349 101.133 120.377V146.783C101.133 147.812 100.653 148.326 99.6935 148.326L96.5682 148.326Z"
                      fill="white"
                    />
                    <path
                      d="M109.151 148.326C108.192 148.326 107.712 147.812 107.712 146.783V120.377C107.712 119.349 108.188 118.834 109.142 118.834H112.164C113.336 118.834 114.224 119.315 114.829 120.274L125.354 137.117H125.456C125.388 136.295 125.354 135.472 125.354 134.65V120.377C125.354 119.349 125.836 118.834 126.803 118.834H129.949C130.916 118.834 131.399 119.349 131.399 120.377V146.783C131.399 147.812 130.919 148.326 129.959 148.326H127.122C125.943 148.326 125.051 147.846 124.446 146.886L113.716 129.734H113.613C113.681 130.557 113.716 131.38 113.716 132.202V146.783C113.716 147.812 113.236 148.326 112.277 148.326L109.151 148.326Z"
                      fill="white"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_387_4394"
                        x1="50.0314"
                        y1="63.4225"
                        x2="60.0359"
                        y2="63.4225"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#182D09" />
                        <stop offset="0.7207" stop-color="#209920" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <p className="Platform-font">
                  Download now to order diesel straight to your doorstep. We're
                  here to streamline and simplify the diesel procurement process
                  for you.
                </p>

                <div className="social-media-icons">
                  <ul>
                    <li>
                      <Link to="">
                        <img
                          src={require("../../Assets/images/app-store.png")}
                        />
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <img
                          src={require("../../Assets/images/play-store.png")}
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={3}></Col>
            <Col lg={3}>
              <div className="footer-list-box">
                <h3>Legal Process</h3>
                <ul>
                  <li>
                    <Link to="#">Terms of service</Link>
                  </li>
                  <li>
                    <Link to="#">Policy privacy</Link>
                  </li>
                  <li>
                    <Link to="#">Services</Link>
                  </li>
                  <li>
                    <Link to="#">About us</Link>
                  </li>
                  <li>
                    <Link to="#">Contact us </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={3}>
              <div className="footer-list-box">
                <h3>Important Links</h3>
                <ul>
                  <li>
                    <Link to="#">Lorem Ipsum</Link>
                  </li>
                  <li>
                    <Link to="/sign-up-as">Lorem Ipsum</Link>
                  </li>
                  <li>
                    <Link to="#">Lorem Ipsum</Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
